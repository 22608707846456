import * as React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import axios from "../../services/http-common";
import { getFormattedTodayDateString } from '../../services/convert';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import moment from "moment";
import "./style.css";
import { useTranslation } from "react-i18next";

const initialRows = [
];

export default function FullFeaturedCrudGrid({ assigner, user, entityName, evalutionState, evalutionCycle }) {
  const { t } = useTranslation();
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [alertmsg, setAlertMsg] = React.useState("");
  const [alerseverity, setAlertSeverity] = React.useState("success");
  const [alertopen, setAlertOpen] = React.useState(false);
  const entity = useSelector((state) => state.user.entity);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      axios.post(`/actionplan`, { entityid: entity })
        .then(resp => {
          var action = resp.data;
          if (!isEmpty(action)) {
            var id = action.id;

            setRows((oldRows) => [...oldRows, { id, action: action.action, expected: action.expected, deadline: new Date(action.deadline), isNew: true }]);
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [id]: { mode: GridRowModes.Edit, fieldToFocus: 'action' },
            }));
          }
        })
        .catch(e => console.log(e));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          {t("tr_add_action")}
        </Button>
      </GridToolbarContainer>
    );
  }

  const CustomNoRowsOverlay = () => {
    return (
      <Empty />
    );
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    axios.delete(`/actionplan/${id}`)
      .then(() => {
        setRows(rows.filter((row) => row.id !== id));
      })
      .catch(e => console.log(e));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    try {
      const updatedRow = { ...newRow, isNew: false };

      await axios.put(`/actionplan/${newRow.id}`, newRow)
        .then(() => {

        })
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFinish = () => {
    axios.post('/finishactions', { entity })
      .then((res) => {
        setAlertSeverity("success");
        setAlertMsg(res.data.message);
        setAlertOpen(true);
      })
      .catch((error) => {
        setAlertSeverity("error");
        setAlertMsg(error.message);
        setAlertOpen(true);
      });
  }

  const getActionPlanInfoDiv = (content) => {
    return (<div className='actionplan-info'>{content}</div>)
  };

  React.useEffect(() => {
    axios.get(`/getallactions/${entity}`)
      .then(resp => {
        var actions = resp.data;

        if (!isEmpty(actions)) {
          setRows(actions.map(action => { action.deadline = new Date(action.deadline); return action; }))
        }
      })
      .catch(e => console.log(e))
  }, [entity])

  return (
    <Grid container spacing={2}>
      <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MuiAlert onClose={handleAlertClose} severity={alerseverity} sx={{ width: '100%' }}>
          {alertmsg}
        </MuiAlert>
      </Snackbar>
      <Grid item xs={6}>
        {t("tr_action_plan_for")} {getActionPlanInfoDiv(entityName)}
      </Grid>
      <Grid item xs={6}>{t("tr_date_of_action_plan")} {getActionPlanInfoDiv(getFormattedTodayDateString())}</Grid>
      <Grid item xs={6}>
        {t("tr_evalution")} {getActionPlanInfoDiv(evalutionState)}
      </Grid>
      <Grid item xs={6}>{t("tr_evaluation_cycle")} {getActionPlanInfoDiv(evalutionCycle)}</Grid>
      <Grid item xs={6}>{t("tr_assigned_to")} {getActionPlanInfoDiv(user)}</Grid>
      <Grid item xs={6}>{t("tr_reviewed_by")} {getActionPlanInfoDiv(assigner)}</Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={[
              { field: 'action', headerName: t("tr_action"), width: 300, editable: true },
              {
                field: 'expected',
                headerName: t("tr_expected"),
                width: 300,
                editable: true,
              },
              {
                field: 'deadline',
                headerName: t("tr_deadline"),
                type: 'date',
                valueFormatter: params =>
                  moment(params?.value).format("YYYY-MM-DD"),
                width: 300,
                editable: true,
              },
              {
                field: 'actions',
                type: 'actions',
                headerName: t("tr_actions"),
                width: 300,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                  if (isInEditMode) {
                    return [
                      <GridActionsCellItem
                        icon={<SaveIcon />}
                        label={t("tr_save")}
                        sx={{
                          color: 'primary.main',
                        }}
                        onClick={handleSaveClick(id)}
                      />,
                      <GridActionsCellItem
                        icon={<CancelIcon />}
                        label={t("tr_cancel")}
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                      />,
                    ];
                  }

                  return [
                    <GridActionsCellItem
                      icon={<EditIcon />}
                      label={t("tr_edit")}
                      className="textPrimary"
                      onClick={handleEditClick(id)}
                      color="inherit"
                    />,
                    <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label={t("tr_delete")}
                      onClick={handleDeleteClick(id)}
                      color="inherit"
                    />
                    ,
                  ];
                },
              },
            ]}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            localeText={{
              MuiTablePagination: {
                labelRowsPerPage: t("tr_rows_per_page"),
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item md={12}>
        <Button variant='contained' color='primary' onClick={() => { handleFinish(); }}>Email list</Button>
      </Grid>
    </Grid>
  );
}