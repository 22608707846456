import React from "react";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PageTitle(props) {
  var classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.pageTitleContainer}>
      <div>
        {props.region && (
          <Button
            onClick={() => {
              history.push("/app/region");
            }}
            disabled={props.button ? true : false}
          >
            <Typography className={classes.typo} variant="h1" size="sm">
              {props.region}
            </Typography>
          </Button>
        )}
        {props.country && (
          <>
            <Button href="#" disabled>
              <Typography className={classes.typo} variant="h1" size="sm">
                /
              </Typography>
            </Button>
            <Button
              onClick={() => {
                history.push("/app/country");
              }}
              disabled={props.button ? true : false}
            >
              <Typography className={classes.typo} variant="h1" size="sm">
                {props.country}
              </Typography>
            </Button>
          </>
        )}
        {props.entity && (
          <>
            <Button href="#" disabled>
              <Typography className={classes.typo} variant="h1" size="sm">
                /
              </Typography>
            </Button>
            <Button
              onClick={() => {
                history.push("/app/entity");
              }}
              disabled={props.button ? true : false}
            >
              <Typography className={classes.typo} variant="h1" size="sm">
                {props.entity}
              </Typography>
            </Button>
          </>
        )}
      </div>

      {props.button && props.button}
    </div>
  );
}
