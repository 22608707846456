import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "@material-ui/icons";
import ScannerSettingDlg from "../../components/Dialog/ScannerSettingDlg";
import CheckOutCalender from "../../components/CheckOutCalendar/CheckOutCalender";
import Widget from "../../components/Widget/Widget";
import axios from "../../services/http-common";
import { setEntity } from "../../redux/reducers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROLE_NUR, evalutionStateStr } from "../../constant/constants";
import { convertToFixed } from "../../services/convert";
import { Segmented } from "antd";
import BellCurveLineChart from "../../components/Charts/BellCurveLineChart";
import ExpiryBarChart from "../../components/Charts/ExpiryBarChart";
import ToricDistribution from "../../components/Charts/ToricDistibutionChart";
import CustomLoadingView from "../../components/LoadingView/CustomLoadingView";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { ADVANCED_EXPIRE_CURVE_GRAPH, BELL_CURVE_GRAPH, CALENDAR_GRAPH, TORIC_DISTRIBUTION_GRAPH } from "../../constant/constants";

export default function Country() {
  const { t } = useTranslation();
  const regionName = useSelector((state) => state.user.regionName);
  const countryName = useSelector((state) => state.user.countryName);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const role = useSelector((state) => state.user.role);
  const isManagerMode = useSelector((state) => state.user.isManagerMode);

  const dispatch = useDispatch();
  const history = useHistory();

  const [opendlg, setDlgOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [entitiesInManager, setEntitiesInManager] = useState([]);
  const [data, setData] = useState([]);
  const [selectedScanner, setSelectedScanner] = useState();
  const [graph, setGraph] = useState(CALENDAR_GRAPH);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var filter = { country };

    if (isManagerMode == true) {
      setIsLoading(true);
      axios
        .post(`/view/${region}/${country}/manager`, filter)
        .then((response) => {
          setIsLoading(false);
          setEntitiesInManager(response.data);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
    else {
      setIsLoading(true);
      axios
        .post(`/view/${region}/${country}`, filter)
        .then((response) => {
          setIsLoading(false);
          setEntities(response.data);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [region, country, isManagerMode]);

  useEffect(() => {
    try {
      var managerData = [];
      var promises = entitiesInManager.map((em) => {
        return axios
          .get(`/stocksize/${em.id}`)
          .then((response) => {
            var entityStockSizeTable = {};

            response.data.map((record) => {
              entityStockSizeTable[record.model] = record.stocksize;
              return record;
            });

            try {
              var entityInfo = {};
              var weekNumber = em.currentWeekNumber;

              entityInfo.name = em.name;
              entityInfo.id = em.id;
              entityInfo.latestaction = em.actionplans?.action;

              entityInfo.avgStockSize = 0;
              entityInfo.YTD = 0;
              entityInfo.forecastUnits = 0;
              entityInfo.forecastToTarget = 0;
              entityInfo.evalution = evalutionStateStr.below;
              entityInfo.weekNumber = weekNumber;

              if (weekNumber > 0) {
                entityInfo.week52TargetUnits = 0;

                try {
                  var summary = {};
                  em.statistics.map((s) => {
                    if (!isEmpty(s.model)) {
                      var model = s.model;
                      var modelv = model;
                      var tpower = s.tpower;

                      tpower = parseFloat(tpower);

                      // if (tpower != 0) {
                      //     modelv = model + `T${tpower}`;
                      // }

                      if (isEmpty(summary[modelv]))
                        summary[modelv] = {
                          model: modelv,
                          checkin: 0,
                          checkout: 0,
                          YTD: 0,
                        };

                      summary[modelv].YTD += s.checkout;
                      summary[modelv].checkin += s.checkin;
                      summary[modelv].checkout += s.checkout;

                      summary[modelv].tpower = tpower;
                      if (tpower != 0) {
                        summary[modelv].turnrate = 1.7;
                      } else if (["XY1-G", "XY1-GP"].includes(modelv)) {
                        summary[modelv].turnrate = 2.5;
                      } else {
                        summary[modelv].turnrate = 4.0;
                      }
                    }
                    return s;
                  });

                  for (const key in summary) {
                    entityInfo.avgStockSize += parseInt(Math.round(summary[key].checkin / weekNumber));
                    entityInfo.YTD += summary[key].YTD;

                    var startingStockSize = entityStockSizeTable[summary[key].model] == undefined ? 0 : entityStockSizeTable[summary[key].model];
                    var turnrate = 0;

                    if (summary[key].tpower != 0) {
                      turnrate = 1.7;
                    } else if (["XY1-G", "XY1-GP"].includes(summary[key].model)) {
                      turnrate = 2.5;
                    } else {
                      turnrate = 4.0;
                    }

                    entityInfo.week52TargetUnits += parseInt(Math.round(startingStockSize * turnrate));
                    entityInfo.forecastUnits += parseInt((summary[key].YTD / weekNumber) * (52 - weekNumber) + summary[key].YTD);
                  }
                  if (entityInfo.week52TargetUnits > 0) {
                    var foreCastToTargetTotal = parseInt(entityInfo.forecastUnits / entityInfo.week52TargetUnits * 100);
                    if (foreCastToTargetTotal < 95) {
                      entityInfo.evalution = evalutionStateStr.below;
                    } else if (foreCastToTargetTotal >= 95 && foreCastToTargetTotal <= 105) {
                      entityInfo.evalution = evalutionStateStr.onpar;
                    } else {
                      entityInfo.evalution = evalutionStateStr.exceeding;
                    }

                    entityInfo.forecastToTarget = `${foreCastToTargetTotal}%`;
                  }
                  managerData.push(entityInfo);
                } catch (e) {
                  console.log(e);
                }
              } else {
                // 0
                managerData.push(entityInfo);
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch(e => console.log(e));
      });
      setIsLoading(true);
      Promise.all(promises)
        .then(() => {
          setIsLoading(false);
          setEntities(managerData);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [entitiesInManager]);

  useEffect(() => {
    if (role === ROLE_NUR) history.push("/app/entity");
    else dispatch(setEntity());
  }, [role, history, dispatch]);

  const hadleEntityClick = (entity, entityName) => {
    dispatch(setEntity({ entity, entityName }));
    history.push("/app/entity");
  };

  const hadleLatestActionClick = (entity, entityName) => {
    dispatch(setEntity({ entity, entityName }));
    history.push("/app/entity?view=actions");
  };

  useEffect(() => {
    if (isManagerMode === true) {
      setData(
        entities.map((entity) => {
          return [
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => {
                hadleEntityClick(entity.id, entity.name);
              }}
            >
              {entity.name}
            </Button>,
            entity.avgStockSize,
            entity.forecastToTarget,
            entity.forecastUnits,
            entity.YTD,
            entity.evalution,
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => {
                hadleLatestActionClick(entity.id, entity.name);
              }}
            >
              {entity.latestaction}
            </Button>,

          ];
        })
      );
    } else {
      setData(
        entities.map((entity) => {
          return [
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => {
                hadleEntityClick(entity.id, entity.name);
              }}
            >
              {entity.name}
            </Button>,
            entity.stock_check,
            `${convertToFixed(entity.turnoverate)}%`,
            entity.m12_count,
            <IconButton
              onClick={() => {
                setSelectedScanner(entity.scanner);
                setDlgOpen(true);
              }}
            >
              <Settings />
            </IconButton>,
          ];
        })
      );
    }

  }, [entities, isManagerMode]);

  const handleDlgClose = () => {
    setDlgOpen(false);
  };

  return (
    <>
      {isLoading && <CustomLoadingView />}
      <PageTitle region={regionName} country={countryName} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget>
            <Segmented
              value={graph}
              options={[
                {
                  value: CALENDAR_GRAPH,
                  label: t("tr_checkout_calendar")
                },
                {
                  value: BELL_CURVE_GRAPH,
                  label: t("tr_bell_curve_graph")
                },
                {
                  value: ADVANCED_EXPIRE_CURVE_GRAPH,
                  label: t("tr_advanced_expiry_curve_graph")
                },
                {
                  value: TORIC_DISTRIBUTION_GRAPH,
                  label: t("tr_toric_distribution_graph")
                },
              ]}
              onChange={(v) => setGraph(v)}
              style={{ marginBottom: 30 }}
            />
            {graph === CALENDAR_GRAPH && <CheckOutCalender />}
            {graph === ADVANCED_EXPIRE_CURVE_GRAPH && <ExpiryBarChart />}
            {graph === BELL_CURVE_GRAPH && <BellCurveLineChart />}
            {graph === TORIC_DISTRIBUTION_GRAPH && <ToricDistribution />}
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title={t("tr_account_list")}
            data={data}
            columns={isManagerMode === true ? [
              t("tr_account_name"),
              t("tr_average_stock_size"),
              t("tr_forecast_to_target"),
              t("tr_forecast"),
              t("tr_ytd_progress"),
              t("tr_evalution"),
              t("tr_latest_action_plan"),
            ] : [
              t("tr_account_name"),
              t("tr_stock_size"),
              t("tr_turnover_rate"),
              t("tr_expiry_date_m"),
              t("tr_actions"),
            ]}
            options={{
              selectableRows: "none",
              textLabels: {
                body: {
                  noMatch: t("tr_sorry_no_matching_records_found"),
                },
                pagination: {
                  rowsPerPage: t("tr_rows_per_page"),
                },
              }
            }}
          />
        </Grid>
      </Grid>
      <ScannerSettingDlg
        open={opendlg}
        onClose={handleDlgClose}
        scannerInfo={selectedScanner}
      />
    </>
  );
}
