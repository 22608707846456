import React, { useState, useEffect } from "react";
import {
  withStyles,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import { QRCodeSVG } from "qrcode.react";
import { isEmpty } from "lodash";
import axios from "../../services/http-common";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
    overflow: "hidden",
  },
  mainHeader: {
    backgroundColor: grey[100],
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 40,
  },
  secondaryContainer: {
    backgroundColor: grey[200],
  },
});
const encryptions = [
  {
    value: "WEP",
    label: "WEP",
  },
  {
    value: "WPA2",
    label: "WPA2",
  },
  {
    value: "WPA3",
    label: "WPA3",
  },
];

function ScannerSettingDlg(props) {
  const { t } = useTranslation();
  const { classes, open, onClose, scannerInfo } = props;
  const [ssid, setSsid] = useState("");
  const [encryption, setEncryption] = useState("");
  const [password, setPassword] = useState("");
  const [serialno, setSerialno] = useState("");
  const [mqpwd, setMqpwd] = useState("");
  const [emqxaddr, setEmqxaddr] = useState("");
  const [qrString, setQRString] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let json = { ssid, encryption, password, serialno, mqpwd, emqxaddr };

    setQRString(JSON.stringify(json));
  }, [ssid, encryption, password, serialno, mqpwd, emqxaddr]);

  useEffect(() => {
    if (!isEmpty(scannerInfo)) {
      setSsid(scannerInfo.ssid);
      setEncryption(scannerInfo.encryption);
      setPassword(scannerInfo.pin);
      setSerialno(scannerInfo.name);
      setMqpwd(scannerInfo.password);
      setEmqxaddr(scannerInfo.emqxaddr);
    }
  }, [scannerInfo]);

  const submitScannerSetting = () => {
    const data = {
      ssid: ssid,
      name: serialno,
      pin: password,
      password: mqpwd,
      encryption: encryption,
      emqxaddr: emqxaddr,
    };

    axios
      .put(`/scanner/${scannerInfo.id}`, data)
      .then(() => {
        onClose();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
    >
      <DialogContent className={classes.padding}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={8}>
                <Typography className={classes.primaryColor} variant="h5">
                  {!isEmpty(scannerInfo) && scannerInfo.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  className={classes.secondaryColor}
                  variant="subtitle1"
                  align="right"
                >
                  {t("tr_scanner_settings")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.mainContent}
              spacing={1}
            >
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={t("tr_wifi_ssid_name")}
                  id="ssid"
                  value={ssid}
                  onChange={(e) => setSsid(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={t("tr_scanner_serial_number")}
                  id="serialno"
                  value={serialno}
                  onChange={(e) => setSerialno(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  label={t("tr_encryption")}
                  fullWidth
                  select
                  variant="outlined"
                  //   value={values.encryption}
                  id="encryption"
                  value={encryption}
                  margin="dense"
                  //   helperText="Please select your encryption"
                  onChange={(e) => setEncryption(e.target.value)}
                >
                  {encryptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={t("tr_emqx_address")}
                  id="emqxaddr"
                  value={emqxaddr}
                  required
                  onChange={(e) => setEmqxaddr(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="password">{t("tr_password")}</InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    label={t("tr_password")}
                    id="password"
                    fullWidth
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="password">{t("tr_mqtt_password")}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    label={t("tr_mqtt_password")}
                    id="mqpwd"
                    value={mqpwd}
                    required
                    onChange={(e) => setMqpwd(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex" }}
                justifyContent="center"
              >
                <QRCodeSVG value={qrString} size={196} align={"center"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.secondaryContainer}>
            <Grid container>
              <Grid item xs={12} align="right" className={classes.padding}>
                <IconButton
                  edge="start"
                  align="right"
                  color="inherit"
                  aria-label="Close"
                  style={{ padding: 8 }}
                  //className={classes.padding}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container className="mt-auto">
              <Grid item>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    color="secondary"
                  >
                    {t("tr_cancel")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" onClick={submitScannerSetting}>
                    {t("tr_save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(ScannerSettingDlg);
