import React, { } from "react";
import "./spinner.css";

export default function CustomLoadingView() {

  return (
    <div className="container">
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    </div>
  );
}
