import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { getCookie } from "../services/cookie";

export const regionSlice = createSlice({
  name: "user",
  initialState: {
    role: localStorage.getItem("role"),
    isAuthenticated: !!getCookie("token"),
    region: localStorage.getItem("region"),
    regionName: localStorage.getItem("regionName"),
    country: localStorage.getItem("country"),
    countryName: localStorage.getItem("countryName"),
    node: localStorage.getItem("node"),
    nodeName: localStorage.getItem("nodeName"),
    entity: localStorage.getItem("entity"),
    entityName: localStorage.getItem("entityName"),
    fmonths: localStorage.getItem("fmonths"),
    startDate: localStorage.getItem("startDate"),
    endDate: localStorage.getItem("endDate"),
    model: localStorage.getItem("model"),
    brand: localStorage.getItem("brand"),
    brandName: localStorage.getItem("brandName"),
    locale: localStorage.getItem("locale") || "en",
    hasmanagerviewpem: localStorage.getItem("hasmanagerviewpem"),
    isManagerMode: localStorage.getItem("isManagerMode") === "true",
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
      localStorage.setItem("role", state.role);
    },
    setHasManagerView: (state, action) => {
      state.hasmanagerviewpem = action.payload;
      localStorage.setItem("hasmanagerviewpem", state.hasmanagerviewpem);
    },
    setIsAunthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
      localStorage.setItem("isAuthenticated", state.isAuthenticated);
    },
    setRegion: (state, action) => {
      if (!isEmpty(action.payload)) {
        if (action.payload.value != undefined) {
          state.region = action.payload.value;
          localStorage.setItem("region", state.region);
        }

        if (action.payload.label != undefined) {
          state.regionName = action.payload.label;
          localStorage.setItem("regionName", state.regionName);
        }
      }
    },
    setNode: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.node = action.payload.value;
        state.nodeName = action.payload.label;
        localStorage.setItem("node", state.node);
        localStorage.setItem("nodeName", state.nodeName);
      }
    },
    setRCountry: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.country = action.payload.value;
        state.countryName = action.payload.label;
        localStorage.setItem("country", state.country);
        localStorage.setItem("countryName", state.countryName);
      } else {
        delete state["country"];
        delete state["countryName"];
        localStorage.removeItem("country");
        localStorage.removeItem("countryName");
      }
    },
    setEntity: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.entity = action.payload.entity;
        state.entityName = action.payload.entityName;
        localStorage.setItem("entity", state.entity);
        localStorage.setItem("entityName", state.entityName);
      } else {
        delete state["entity"];
        delete state["entityName"];
        localStorage.removeItem("entity");
        localStorage.removeItem("entityName");
      }
    },
    setMonthFilter: (state, action) => {
      state.fmonths = action.payload;
      localStorage.setItem("fmonths", state.fmonths);
    },
    setRangeFilter: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.startDate = action.payload["start"];
        state.endDate = action.payload["end"];
        localStorage.setItem("startDate", state.startDate);
        localStorage.setItem("endDate", state.endDate);
      }
    },
    setModelFilter: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.model = action.payload;
        localStorage.setItem("model", state.model);
      } else {
        delete state["model"];
        localStorage.removeItem("model");
      }
    },
    setBrandFilter: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.brand = action.payload;
        localStorage.setItem("brand", state.brand);
      } else {
        delete state["brand"];
        localStorage.removeItem("brand");
      }
    },
    setSelectedBrandName: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.brandName = action.payload;
        localStorage.setItem("brandName", state.brandName);
      } else {
        delete state["brandName"];
        localStorage.removeItem("brandName");
      }
    },
    setLocale: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.locale = action.payload;
        localStorage.setItem("locale", state.locale);
      }
    },
    setMode: (state, action) => {
      state.isManagerMode = action.payload;
      localStorage.setItem("isManagerMode", state.isManagerMode);
    },

    resetState: (state, action) => {
      delete state["role"];
      delete state["hasmanagerviewpem"];
      delete state["region"];
      delete state["entity"];
      delete state["country"];
      delete state["node"];
      delete state["regionName"];
      delete state["entityName"];
      delete state["countryName"];
      delete state["nodeName"];
      delete state["fmonths"];
      delete state["startDate"];
      delete state["endDate"];
      delete state["model"];
      delete state["brand"];
      delete state["brandName"];
      state.isManagerMode = false;
      state.isAuthenticated = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRegion,
  setRole,
  setHasManagerView,
  setNode,
  setRCountry,
  setMonthFilter,
  setRangeFilter,
  setModelFilter,
  setBrandFilter,
  setSelectedBrandName,
  setLocale,
  setEntity,
  resetState,
  setIsAunthenticated,
  setMode,
} = regionSlice.actions;

export default regionSlice.reducer;
