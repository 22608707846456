import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { List, ListItemButton } from "@mui/material";
import axios from "../../services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { setBrandFilter, setModelFilter, setSelectedBrandName } from "../../redux/reducers";
import { Box } from "@material-ui/core";
import { Divider } from 'antd';
import CustomFilterComponent from "../CustomFilterComponent/CustomFilterComponent";
import { Segmented } from "antd";
import { isEmpty } from "lodash";
import { Sorter } from "../../services/sorter";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: "white",
    borderColor: "rgb(0, 0, 0, 0.12)",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "none",
    width: "250px",
  },

  toolbar: theme.mixins.toolbar,
}));

const FILTER_MODEL = "MODEL";
const FILTER_BRAND = "BRAND";

const RightNavigation = () => {
  const { t } = useTranslation();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);
  const brandName = useSelector((state) => state.user.brandName);
  const entity = useSelector((state) => state.user.entity);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(0);
  const [brands, setBrands] = useState({ All: [] });
  const [models, setModels] = useState(["All"]);
  const [filter, setFilter] = useState(FILTER_MODEL);

  useEffect(() => {
    var filter = { country, startDate, endDate, checkin: true, entity };

    if (region == undefined)
      return;

    axios
      .post(`/view/${region}/getAvaialbeModels`, filter)
      .then((response) => {
        const data = [];
        const dict_modelv = {};
        var index = 0;

        response.data.sort(Sorter.STRING("modelv")).map((item) => {
          if (dict_modelv[item.modelv] == undefined) {
            data.push(item.modelv);
            index++;
            dict_modelv[item.modelv] = [item.model];
          } else {
            dict_modelv[item.modelv].push(item.model);
          }

          if (item.modelv === model) {
            setSelectedIndex(index);
          };
          return item;
        });

        axios
          .post(`/ean/getAvaialbeBrands`, { models: data })
          .then((res) => {
            var dict_brand = [];
            var brand_index = 0;

            res.data.sort(Sorter.STRING("brand")).map((record, index) => {
              if (dict_modelv[record.modelv].includes(record.model)) {
                if (isEmpty(dict_brand[record.brand])) {
                  dict_brand[record.brand] = [];
                  brand_index++;
                }
                dict_brand[record.brand].push(record.model);
                if (record.brand == brandName) {
                  setSelectedIndex(brand_index);
                };
              }
            });

            setBrands({ All: [], ...dict_brand });
          })
          .catch((error) => {
            console.log(error);
          });

        setModels(["All", ...data.sort()]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, country, startDate, endDate, entity]);

  useEffect(() => {
    if (selectedIndex !== 0)
      dispatch(setModelFilter(models[selectedIndex]));
    else dispatch(setModelFilter());
  }, [selectedIndex, dispatch, models]);

  useEffect(() => {
    if (!isEmpty(brands)) {
      if (selectedBrandIndex !== 0) {
        dispatch(setSelectedBrandName(Object.keys(brands)[selectedBrandIndex]));
        dispatch(setBrandFilter(brands[Object.keys(brands)[selectedBrandIndex]]));
      }
      else {
        dispatch(setBrandFilter());
        dispatch(setSelectedBrandName());
      }
    }
  }, [selectedBrandIndex, dispatch, brands]);

  useEffect(() => {
    // console.log("brand", brand);
    // console.log("brandName", brandName);
  }, [brand, brandName])

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleListBrandItemClick = (event, index) => {
    setSelectedBrandIndex(index);
  };

  return (
    <Box className={classes.drawer}>
      <div className={classes.toolbar} />
      <CustomFilterComponent />
      <Divider>{t("tr_model_filter")}</Divider>
      <Box display={"flex"} justifyContent={"center"}>
        <Segmented
          value={filter}
          options={[{
            value: FILTER_MODEL,
            label: t("tr_model")
          },
          {
            value: FILTER_BRAND,
            label: t("tr_brand")
          }
          ]}
          onChange={(f) => {
            setFilter(f);
            if (f == FILTER_MODEL) {
              setSelectedBrandIndex(0);
            } else if (f == FILTER_BRAND) {
              setSelectedIndex(0);
            }
          }}
        />
      </Box>
      <List>
        {filter == FILTER_MODEL ? models.map((model, index) => {
          return (
            <ListItemButton
              selected={selectedIndex === index}
              key={index}
              onClick={(event) => handleListItemClick(event, index)}
              style={{ paddingBottom: 1, paddingTop: 1 }}
            >
              {model == "All" ? t("tr_all") : model}
            </ListItemButton>
          );
        }) : Object.keys(brands).map((brand, index) => {
          return (
            <ListItemButton
              selected={selectedBrandIndex === index}
              key={index}
              onClick={(event) => handleListBrandItemClick(event, index)}
              style={{ paddingBottom: 1, paddingTop: 1 }}
            >
              {brand == "All" ? t("tr_all") : brand}
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

export default RightNavigation;
