import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
// import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

// context
import { useLayoutState } from "../../context/LayoutContext";
import RightNavigation from "../Sidebar/RightNavigation";
import Country from "../../pages/country/Country";
import Entity from "../../pages/entity/Entity";
import ActionPlan from "../../pages/entity/ActionPlan";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        {/* <Sidebar /> */}
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/region" component={Dashboard} />
            <Route path="/app/country" component={Country} />
            <Route path="/app/entity/actionplan" component={ActionPlan} />
            <Route path="/app/entity" component={Entity} />
          </Switch>
        </div>
        <RightNavigation />
      </>
    </div>
  );
}

export default withRouter(Layout);
