exports.ROLE_NUR = 4;
exports.ROLE_MID = 3;
exports.ROLE_MAIN = 2;
exports.ROLE_ADMIN = 1;

exports.REPORT_DAILY = 1;
exports.REPORT_WEEKLY = 2;
exports.REPORT_MONTHLY = 3;

exports.languages = [
  { value: "en", label: "English", icon: "gb" },
  { value: "de", label: "Deutsch", icon: "de" },
  { value: "fr", label: "Français", icon: "fr" },
  { value: "pt", label: "Portugal", icon: "pt" },
  { value: "es", label: "Española", icon: "es" },
  { value: "it", label: "Italiana", icon: "it" },
  { value: "jp", label: "日本語", icon: "jp" },
  // Add more language options as needed
];

exports.STATUS_MISSED = 1;
exports.STATUS_CONFIRMED = 2;
exports.STATUS_NEW = 3;

exports.STATUS_MISSED_STR = "Missed";
exports.STATUS_CONFIRMED_STR = "Confirmed";
exports.STATUS_NEW_STR = "New";
exports.STATUS_UNKNOWN_STR = "UNKNOWN";

exports.WEEKDAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
exports.TIMEOFDAY = [
  { label: "00:00", value: "00:00:00" },
  { label: "01:00", value: "01:00:00" },
  { label: "02:00", value: "02:00:00" },
  { label: "03:00", value: "03:00:00" },
  { label: "04:00", value: "04:00:00" },
  { label: "05:00", value: "05:00:00" },
  { label: "06:00", value: "06:00:00" },
  { label: "07:00", value: "07:00:00" },
  { label: "08:00", value: "08:00:00" },
  { label: "09:00", value: "09:00:00" },
  { label: "10:00", value: "10:00:00" },
  { label: "11:00", value: "11:00:00" },
  { label: "12:00", value: "12:00:00" },
  { label: "13:00", value: "13:00:00" },
  { label: "14:00", value: "14:00:00" },
  { label: "15:00", value: "15:00:00" },
  { label: "16:00", value: "16:00:00" },
  { label: "17:00", value: "17:00:00" },
  { label: "18:00", value: "18:00:00" },
  { label: "19:00", value: "19:00:00" },
  { label: "20:00", value: "20:00:00" },
  { label: "21:00", value: "21:00:00" },
  { label: "22:00", value: "22:00:00" },
  { label: "23:00", value: "23:00:00" },
];


exports.evalutionStateStr = {
  below: "BELOW TARGET",
  onpar: "On PAR",
  exceeding: "EXCEEDING"
};

exports.ACTIONSTATUS = ["On track", "Behind, but progressing", "Behind, urgent action needed", "Completed", "Revised and closed"];

exports.CALENDAR_GRAPH = 0;
exports.BELL_CURVE_GRAPH = 1;
exports.ADVANCED_EXPIRE_CURVE_GRAPH = 2;
exports.TORIC_DISTRIBUTION_GRAPH = 3;