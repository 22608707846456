import React, { useEffect, useState } from "react";
import {
  withStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Select,
  MenuItem,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import axios from "../../services/http-common";
import { useSelector } from "react-redux";
import {
  REPORT_DAILY,
  REPORT_MONTHLY,
  REPORT_WEEKLY,
  WEEKDAY,
  TIMEOFDAY,
} from "../../constant/constants";
import { Divider, Space } from "antd";
import { makeStyles } from "@material-ui/styles";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
    overflow: "hidden",
  },
  mainHeader: {
    backgroundColor: grey[100],
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 40,
  },
  secondaryContainer: {
    backgroundColor: grey[100],
  },
  gridItem: {
    marginLeft: "75px",
    marginRight: "75px",
  },
});

const useStyles = makeStyles((theme) => ({
  alignlb: {
    display: "flex",
    justifyContent: "space-between",
  },

  toolbar: theme.mixins.toolbar,
}));

const timezones = moment.tz
  .names()
  .map((timezone) => ({ id: timezone, name: timezone }));

function ScannerSettingDlg(props) {
  const { t } = useTranslation();
  const { classes, open, onClose } = props;
  const entity = useSelector((state) => state.user.entity);
  const entityName = useSelector((state) => state.user.entityName);

  const [isSendSummary, setIsSendSummary] = useState(false);
  const [isOverviewExpireDate, setIsOverviewExpireDate] = useState(false);
  const [isAutoReplReq, setIsAutoReplReq] = useState(false);
  const [billAddr, setBillAddr] = useState("");
  const [reportEmail, setReportEmail] = useState("");
  const [reportEmailPurchase, setReportEmailPurchase] = useState("");
  const [shippingAddr, setShippingAddr] = useState("");
  const [reportweekday, setReportWeekday] = useState("Mon");
  const [reporttime, setReportTime] = useState("16:00:00");
  const [reporttimezone, setReportTimezone] = useState("Europe/Berlin");
  const [reportweekdaypurchase, setReportPurchaseWeekday] = useState("Mon");
  const [reporttimepurchase, setReportPurchaseTime] = useState("16:00:00");
  const [reporttimezonepurchase, setReportPurchaseTimezone] =
    useState("Europe/Berlin");
  const [freqauto, setFreqAuto] = useState(REPORT_DAILY);
  const [freqrep, setFreqRep] = useState(REPORT_DAILY);
  const [reportexpireinmonth, setReportexpireInMonth] = useState(1);
  const [reportexpiretousers, setReportExpireToUsers] = useState("");
  const [defaultreporttousers, setDefaultToUser] = useState("");
  const [delaydaysforinternalreport, setDelayDaysForInternalReport] = useState(1);
  const [delaydaysforpurchasereport, setDelayDaysForPurchaseReport] = useState(1);
  const self_classes = useStyles();

  const submitAccountSetting = () => {
    const data = {
      id: entity,
      billinaddr: billAddr,
      freqauto: freqauto,
      freqrep: freqrep,
      isautorepl: isAutoReplReq,
      isexpdate: isOverviewExpireDate,
      issendsummary: isSendSummary,
      reportemail: reportEmail,
      reportemailpurchase: reportEmailPurchase,
      shoppingaddr: shippingAddr,
      reportweekday: reportweekday,
      reporttime: reporttime,
      reporttimezone: reporttimezone,
      reportweekdaypurchase: reportweekdaypurchase,
      reporttimepurchase: reporttimepurchase,
      reporttimezonepurchase: reporttimezonepurchase,
      reportexpireinmonth: reportexpireinmonth,
      reportexpiretousers: reportexpiretousers,
      delaydaysforinternalreport: delaydaysforinternalreport,
      delaydaysforpurchasereport: delaydaysforpurchasereport,
    };
    axios
      .put(`/entity/${entity}`, data)
      .then((resp) => {
        onClose();
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    axios
      .get(`/entityinfo/${entity}`)
      .then((resp) => {
        const profile = resp.data;
        setIsSendSummary(profile.issendsummary);
        setIsOverviewExpireDate(profile.isexpdate);
        setIsAutoReplReq(profile.isautorepl);
        setBillAddr(profile.billinaddr);
        setReportEmail(profile.reportemail);
        setReportEmailPurchase(profile.reportemailpurchase);
        setShippingAddr(profile.shoppingaddr);
        setFreqAuto(profile.freqauto);
        setFreqRep(profile.freqrep);
        setReportTime(profile.reporttime);
        setReportTimezone(profile.reporttimezone);
        setReportWeekday(profile.reportweekday);
        setReportPurchaseTime(profile.reporttimepurchase);
        setReportPurchaseTimezone(profile.reporttimezonepurchase);
        setReportPurchaseWeekday(profile.reportweekdaypurchase);
        setReportexpireInMonth(profile.reportexpireinmonth);
        setReportExpireToUsers(profile.reportexpiretousers);
        setDefaultToUser(profile.manager?.email);
        setDelayDaysForInternalReport(profile.delaydaysforinternalreport);
        setDelayDaysForPurchaseReport(profile.delaydaysforpurchasereport);
      })
      .catch((e) => console.log(e));
  }, [entity]);

  return (
    <Dialog
      className={classes.root}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
    >
      <DialogContent className={classes.padding} style={{ overflowY: 'auto' }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={4}>
                <Typography className={classes.primaryColor} variant="h5">
                  {entityName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {/* <Typography
                  className={classes.secondaryColor}
                  variant="subtitle1"
                  align="right"
                >
                  Account Info
                </Typography> */}
              </Grid>
              <Grid item xs={4}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    color="secondary"
                  >
                    {t("tr_cancel")}
                  </Button>
                  <Button color="primary" onClick={submitAccountSetting}>
                    {t("tr_save")}
                  </Button>
                  <IconButton
                    edge="start"
                    align="right"
                    color="inherit"
                    aria-label="Close"
                    style={{ padding: 8 }}
                    //className={classes.padding}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.mainContent}
              spacing={2}
            >
              <Grid item xs={12}>
                <Divider orientation="left">{t("tr_internal_reports")}</Divider>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <RadioGroup
                      row
                      defaultValue={false}
                      value={isSendSummary}
                      onChange={(e) =>
                        setIsSendSummary(JSON.parse(e.target.value))
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("tr_yes")}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("tr_no")}
                      />
                    </RadioGroup>
                  }
                  className={self_classes.alignlb}
                  label={t("tr_send_summary_of_checked_out_iols")}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <RadioGroup
                      row
                      defaultValue={REPORT_DAILY}
                      value={freqrep}
                      onChange={(e) => setFreqRep(parseInt(e.target.value))}
                    >
                      <FormControlLabel
                        value={REPORT_DAILY}
                        control={<Radio />}
                        label={t("tr_daily")}
                        disabled={!isSendSummary}
                      />
                      <FormControlLabel
                        value={REPORT_WEEKLY}
                        control={<Radio />}
                        label={t("tr_weekly")}
                        disabled={!isSendSummary}
                      />
                      <FormControlLabel
                        value={REPORT_MONTHLY}
                        control={<Radio />}
                        label={t("tr_immediately")}
                        disabled={/*!isSendSummary*/true}
                        hidden={true}
                      />
                    </RadioGroup>
                  }
                  label={t("tr_frequency")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              {isSendSummary && freqrep != REPORT_MONTHLY && (
                <>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Box display={"flex"} justifyContent={"center"}>
                      {freqrep == REPORT_WEEKLY && (
                        <FormControlLabel
                          value={reportweekday}
                          control={
                            <Select
                              onChange={(e) => {
                                setReportWeekday(e.target.value);
                              }}
                            >
                              {WEEKDAY.map((w) => (
                                <MenuItem value={w}>{w}</MenuItem>
                              ))}
                            </Select>
                          }
                          label={t("tr_weekday")}
                          labelPlacement="top"
                          disabled={!isSendSummary}
                        />
                      )}
                      <FormControlLabel
                        value={reporttime}
                        control={
                          <Select
                            onChange={(e) => {
                              setReportTime(e.target.value);
                            }}
                          >
                            {TIMEOFDAY.map((time) => (
                              <MenuItem value={time.value}>{time.label}</MenuItem>
                            ))}
                          </Select>
                        }
                        label={t("tr_time")}
                        labelPlacement="top"
                        disabled={!isSendSummary}
                      />
                      <FormControlLabel
                        value={reporttimezone}
                        control={
                          <Select
                            onChange={(e) => {
                              setReportTimezone(e.target.value);
                            }}
                          >
                            {timezones.map((t) => (
                              <MenuItem value={t.name}>{t.name}</MenuItem>
                            ))}
                          </Select>
                        }
                        label={t("tr_timezone")}
                        labelPlacement="top"
                        disabled={!isSendSummary}
                      />
                    </Box>
                  </Grid>
                  {freqrep == REPORT_DAILY && (
                    <Grid item xs={12} className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Space>
                              <TextField
                                value={delaydaysforinternalreport}
                                style={{ width: "100px" }}
                                onChange={(e) => setDelayDaysForInternalReport(e.target.value < 1 ? 1 : e.target.value)}
                                type="number"
                              />
                              <p>{t("tr_days")}</p>
                            </Space>
                          </Box>
                        }
                        label={t("tr_delay_days_for_report")}
                        labelPlacement="start"
                        className={self_classes.alignlb}
                      />
                    </Grid>)}
                </>
              )}
              {/* <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <RadioGroup
                      row
                      defaultValue={false}
                      value={isOverviewExpireDate}
                      onChange={(e) =>
                        setIsOverviewExpireDate(JSON.parse(e.target.value))
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("tr_yes")}
                        disabled={!isSendSummary}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("tr_no")}
                        disabled={!isSendSummary}
                      />
                    </RadioGroup>
                  }
                  label="Include overview of expiration  dates?"
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid> */}
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box>
                      <TextField
                        type="email"
                        value={reportEmail}
                        onChange={(e) => setReportEmail(e.target.value)}
                        disabled={!isSendSummary}
                        style={{ width: "300px" }}
                      />
                    </Box>
                  }
                  label={t("tr_email_addresses_for_this_report")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider orientation="left">{t("tr_purchase_orders")}</Divider>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <RadioGroup
                      row
                      defaultValue={false}
                      value={isAutoReplReq}
                      onChange={(e) =>
                        setIsAutoReplReq(JSON.parse(e.target.value))
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("tr_yes")}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("tr_no")}
                      />
                    </RadioGroup>
                  }
                  label={t("tr_generate_automatic_replenishment_requests")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <RadioGroup
                      row
                      defaultValue={REPORT_DAILY}
                      value={freqauto}
                      onChange={(e) => setFreqAuto(parseInt(e.target.value))}
                    >
                      <FormControlLabel
                        value={REPORT_DAILY}
                        control={<Radio />}
                        label={t("tr_daily")}
                        disabled={!isAutoReplReq}
                      />
                      <FormControlLabel
                        value={REPORT_WEEKLY}
                        control={<Radio />}
                        label={t("Weekly")}
                        disabled={!isAutoReplReq}
                      />
                      <FormControlLabel
                        value={REPORT_MONTHLY}
                        control={<Radio />}
                        label={t("tr_immediately")}
                        disabled={/*!isAutoReplReq*/true}
                      />
                    </RadioGroup>
                  }
                  label={t("tr_frequency")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              {isAutoReplReq && freqauto != REPORT_MONTHLY && (
                <>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Box display={"flex"} justifyContent={"center"}>
                      {freqauto == REPORT_WEEKLY && (
                        <FormControlLabel
                          value={reportweekdaypurchase}
                          control={
                            <Select
                              onChange={(e) => {
                                setReportPurchaseWeekday(e.target.value);
                              }}
                            >
                              {WEEKDAY.map((w) => (
                                <MenuItem value={w}>{w}</MenuItem>
                              ))}
                            </Select>
                          }
                          label={t("tr_weekday")}
                          labelPlacement="top"
                          disabled={!isAutoReplReq}
                        />
                      )}
                      <FormControlLabel
                        value={reporttimepurchase}
                        control={
                          <Select
                            onChange={(e) => {
                              setReportPurchaseTime(e.target.value);
                            }}
                          >
                            {TIMEOFDAY.map((time) => (
                              <MenuItem value={time.value}>{time.label}</MenuItem>
                            ))}
                          </Select>
                        }
                        label={t("tr_time")}
                        labelPlacement="top"
                        disabled={!isAutoReplReq}
                      />
                      <FormControlLabel
                        value={reporttimezonepurchase}
                        control={
                          <Select
                            onChange={(e) => {
                              setReportPurchaseTimezone(e.target.value);
                            }}
                          >
                            {timezones.map((t) => (
                              <MenuItem value={t.name}>{t.name}</MenuItem>
                            ))}
                          </Select>
                        }
                        label={t("tr_timezone")}
                        labelPlacement="top"
                        disabled={!isAutoReplReq}
                      />
                    </Box>
                  </Grid>
                  {freqauto == REPORT_DAILY && (
                    <Grid item xs={12} className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Space>
                              <TextField
                                value={delaydaysforpurchasereport}
                                style={{ width: "100px" }}
                                onChange={(e) => setDelayDaysForPurchaseReport(e.target.value < 1 ? 1 : e.target.value)}
                                type="number"
                              />
                              <p>{t("tr_days")}</p>
                            </Space>
                          </Box>
                        }
                        label={t("tr_delay_days_for_report")}
                        labelPlacement="start"
                        className={self_classes.alignlb}
                      />
                    </Grid>)}
                </>
              )}
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box>
                      <TextField
                        type="email"
                        value={reportEmailPurchase}
                        onChange={(e) => setReportEmailPurchase(e.target.value)}
                        disabled={!isAutoReplReq}
                        style={{ width: "300px" }}
                      />
                    </Box>
                  }
                  label={t("tr_email_addresses_for_this_report")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box>
                      <TextField
                        value={billAddr}
                        style={{ width: "400px" }}
                        onChange={(e) => setBillAddr(e.target.value)}
                        disabled={!isAutoReplReq}
                      />
                    </Box>
                  }
                  label={t("tr_billing_address")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box>
                      <TextField
                        value={shippingAddr}
                        style={{ width: "400px" }}
                        onChange={(e) => setShippingAddr(e.target.value)}
                        disabled={!isAutoReplReq}
                      />
                    </Box>
                  }
                  label={t("tr_shipping_address")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider orientation="left">{t("tr_expiry_date_report")}</Divider>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <Space>
                        <TextField
                          value={reportexpireinmonth}
                          style={{ width: "100px" }}
                          onChange={(e) => setReportexpireInMonth(e.target.value < 1 ? 1 : e.target.value)}
                          type="number"
                        />
                        <p>{t("tr_months")}</p>
                      </Space>
                    </Box>
                  }
                  label={t("tr_report_period")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box>
                      <Space>
                        <TextField
                          value={defaultreporttousers}
                          style={{ width: "400px" }}
                          disabled={true}
                        />
                      </Space>
                    </Box>
                  }
                  label={t("tr_manager")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Box >
                      <Space>
                        <TextField
                          value={reportexpiretousers}
                          style={{ width: "400px" }}
                          onChange={(e) => setReportExpireToUsers(e.target.value)}
                        />
                      </Space>
                    </Box>
                  }
                  label={t("tr_additional_users")}
                  labelPlacement="start"
                  className={self_classes.alignlb}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(ScannerSettingDlg);
