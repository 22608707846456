import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import CheckOutCalender from "../../components/CheckOutCalendar/CheckOutCalender";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import { useSelector } from "react-redux";

import { Segmented } from "antd";
import BellCurveLineChart from "../../components/Charts/BellCurveLineChart";
import ExpiryBarChart from "../../components/Charts/ExpiryBarChart";
import Widget from "../../components/Widget/Widget";
import ToricDistribution from "../../components/Charts/ToricDistibutionChart";
import EntityManager from "./EntityManager";
import EntityNormal from "./EntityNormal";
import { useTranslation } from "react-i18next";
import { ADVANCED_EXPIRE_CURVE_GRAPH, BELL_CURVE_GRAPH, CALENDAR_GRAPH, TORIC_DISTRIBUTION_GRAPH } from "../../constant/constants";

export default function Entity() {
  const { t } = useTranslation();
  const regionName = useSelector((state) => state.user.regionName);
  const countryName = useSelector((state) => state.user.countryName);
  const entityName = useSelector((state) => state.user.entityName);
  const isManagerMode = useSelector((state) => state.user.isManagerMode);
  const [graph, setGraph] = useState(CALENDAR_GRAPH);

  return (
    <>
      <PageTitle
        region={regionName}
        country={countryName}
        entity={entityName}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Widget>
            <Segmented
              value={graph}
              options={[
                {
                  value: CALENDAR_GRAPH,
                  label: t("tr_checkout_calendar")
                },
                {
                  value: BELL_CURVE_GRAPH,
                  label: t("tr_bell_curve_graph")
                },
                {
                  value: ADVANCED_EXPIRE_CURVE_GRAPH,
                  label: t("tr_advanced_expiry_curve_graph")
                },
                {
                  value: TORIC_DISTRIBUTION_GRAPH,
                  label: t("tr_toric_distribution_graph")
                },
              ]}
              onChange={(v) => setGraph(v)}
              style={{ marginBottom: 30 }}
            />
            {graph === CALENDAR_GRAPH && <CheckOutCalender />}
            {graph === ADVANCED_EXPIRE_CURVE_GRAPH && <ExpiryBarChart />}
            {graph === BELL_CURVE_GRAPH && <BellCurveLineChart />}
            {graph === TORIC_DISTRIBUTION_GRAPH && <ToricDistribution />}
          </Widget>
        </Grid>
        {isManagerMode == false ?
          <EntityNormal /> : <EntityManager />}
      </Grid>
    </>
  );
}
