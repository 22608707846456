import React, { useEffect, useState } from 'react';
import { Space, Table, Switch, Typography } from 'antd';

import EditableTable from './EditableTable'
import { Box, Button, Grid } from '@material-ui/core';
import PageTitle from "../../components/PageTitle/PageTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../services/http-common";
import { isEmpty } from 'lodash';
import { calculateDiffWeeks } from '../../services/convert';
import CustomLoadingView from '../../components/LoadingView/CustomLoadingView';
import { Sorter } from '../../services/sorter';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const TodoActionPlans = () => {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state) => state.user.locale);
    const history = useHistory();    

    const [isCountryView, setIsCountryView] = useState(false);
    const [isShowOnlyNotComplete, setIsShowOnlyNotComplete] = useState(false);
    const [data, setData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`/gettodoactions`)
            .then((response) => {
                setIsLoading(false);
                if (response != null) {
                    var flag = response.data.isCountryManager;
                    var resData = [];

                    if (flag === true) {
                        setIsCountryView(flag);
                    } else {
                        setIsCountryView(flag);
                    }

                    response.data?.entities?.map((entity) => {
                        var scannedat = entity.scannedat;
                        if (!isEmpty(scannedat)) {
                            try {
                                var actionplans = {};
                                entity.actionplans.map((actionplan) => {
                                    var offset = (calculateDiffWeeks(scannedat) - calculateDiffWeeks(actionplan.createdat)) % 52 + 1;
                                    if (isEmpty(actionplans[offset]))
                                        actionplans[offset] = [];
                                    actionplans[offset].push(actionplan);
                                    return actionplan;
                                });

                                resData.push({
                                    user: entity.assigners,
                                    entity: entity.name,
                                    entityid: entity.id,
                                    actionplans: actionplans
                                });
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    });

                    var data = [];
                    resData.map(d => {
                        try {
                            if (!isEmpty(d.actionplans)) {
                                const keys = Object.keys(d.actionplans);
                                keys.forEach(key => {
                                    data.push({
                                        user: d.user,
                                        entity: d.entity,
                                        planweek: `${t("tr_action_plan")} W${key}`,
                                        week: key,
                                        actionplans: d.actionplans[key],
                                        key: `${d.entityid}${key}`
                                    })
                                });
                            }
                            return d;
                        }
                        catch (e) {
                            console.log(e);
                        }
                    });
                    setData(data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }, []);

    const expandedRowRender = (rowRecord) => {
        var subtableData = [];

        if (!isEmpty(rowRecord.actionplans))
            subtableData = rowRecord.actionplans;

        if (isEmpty(subtableData))
            subtableData = [];

        subtableData = subtableData.map(record => { return { ...record, key: record.id } });

        return <EditableTable dataSource={subtableData} isShowOnlyNotComplete={isShowOnlyNotComplete} />;
    };

    useEffect(() => {
        if (!isEmpty(data))
            setExpandedRows(data.map(o => o.key));
    }, [data]);

    return (
        <Box sx={{ m: 5 }}>
            {isLoading && <CustomLoadingView />}
            <PageTitle
                // region={ }
                button={
                    <Box display={"flex"} justifyContent={"flex-end"}>
                        <Space size="middle">
                            <Box display={"flex"}>
                                <Space size="small">
                                    <Typography>
                                        {t("tr_not_completed")}
                                    </Typography>
                                    <Switch defaultChecked={isShowOnlyNotComplete} onChange={(e) => {
                                        setIsShowOnlyNotComplete(e);
                                    }} />
                                </Space>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                {t("tr_go_to_dashboard")}
                            </Button>
                        </Space>
                    </Box>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {data.length >= 0 &&
                        <Table
                            columns={isCountryView ? [
                                {
                                    title: t("tr_user"),
                                    dataIndex: 'user',
                                    key: 'user',
                                    sorter: {
                                        compare: Sorter.DEFAULT('user')
                                    }
                                },
                                {
                                    title: t("tr_action_plan"),
                                    dataIndex: 'planweek',
                                    key: 'planweek',
                                    sorter: {
                                        compare: Sorter.DEFAULT('planweek')
                                    }
                                },
                                {
                                    title: t("tr_entity"),
                                    dataIndex: 'entity',
                                    key: 'entity',
                                    sorter: {
                                        compare: Sorter.DEFAULT('entity')
                                    }
                                },
                                {
                                    title: t("tr_actions"),
                                    dataIndex: 'action',
                                    key: 'action',
                                },
                                {
                                    title: t("tr_expected_result"),
                                    dataIndex: 'expected',
                                    key: 'expected',
                                },
                                {
                                    title: t("tr_deadline"),
                                    dataIndex: 'deadline',
                                    key: 'deadline',
                                },
                                {
                                    title: t("tr_status"),
                                    dataIndex: 'status',
                                    key: 'status',
                                }

                            ] : [
                                {
                                    title: t("tr_action_plan"),
                                    dataIndex: 'planweek',
                                    key: 'planweek',
                                    sorter: {
                                        compare: Sorter.DEFAULT('planweek')
                                    }
                                },
                                {
                                    title: t("tr_entity"),
                                    dataIndex: 'entity',
                                    key: 'entity',
                                    sorter: {
                                        compare: Sorter.DEFAULT('entity')
                                    }
                                },
                                {
                                    title: t("tr_actions"),
                                    dataIndex: 'action',
                                    key: 'action',
                                },
                                {
                                    title: t("tr_expected_result"),
                                    dataIndex: 'expected',
                                    key: 'expected',
                                },
                                {
                                    title: t("tr_deadline"),
                                    dataIndex: 'deadline',
                                    key: 'deadline',
                                },
                                {
                                    title: t("tr_status"),
                                    dataIndex: 'status',
                                    key: 'status',
                                }
                            ]}
                            expandable={{
                                expandedRowKeys: expandedRows,
                                expandedRowRender,
                                defaultExpandAllRows: true,
                                onExpandedRowsChange: ((change) => setExpandedRows(change))
                            }}
                            dataSource={data}

                        />
                    }
                </Grid>
            </Grid>
        </Box>

    );
};
export default TodoActionPlans;