import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "flag-icons/css/flag-icons.min.css";
import { languages } from "../../constant/constants";
import { setLocale } from "../../redux/reducers";
import { isEmpty } from "lodash";

function LanguageSwitcher() {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.user.locale);
  const [selectedLanguage, setSelectedLanguage] = useState(locale);

  const { i18n } = useTranslation();
  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  useEffect(() => {
    if (!isEmpty(selectedLanguage)) {
      i18n.changeLanguage(selectedLanguage);
      dispatch(setLocale(selectedLanguage));
    }
  }, [selectedLanguage, dispatch])


  return (
    <Select
      value={selectedLanguage}
      onChange={handleChange}
      disableUnderline
      sx={{ display: "flex", alignItems: "center" }}
    >
      {languages.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {/* <Box sx={{ marginRight: 1 }}>
            <FlagIcon code={option.icon} size="1x" />
          </Box> */}
          <span
            class={`fi fi-${option.icon} fis`}
            style={{ marginRight: 5 }}
          ></span>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default LanguageSwitcher;
