import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// locale
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";

// context
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import { useDispatch } from "react-redux";
import { loginUser } from "../../context/UserContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Login(props) {
  var classes = useStyles();
  // locale
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>
          e<span style={{ fontFamily: "DinProBold" }}>Consignment</span>
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <img src={logo} alt="logo" className={classes.logtypeImage1} />
        <LanguageSwitcher />
        <div className={classes.form}>
          <React.Fragment>
            <Fade
              in={error}
              style={!error ? { display: "none" } : { display: "block" }}
            >
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder={t("tr_email_address")}
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder={t("tr_password")}
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={() =>
                    loginUser(
                      dispatch,
                      loginValue,
                      passwordValue,
                      history,
                      setIsLoading,
                      setError
                    )
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t("tr_login")}
                </Button>
              )}
              <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                onClick={() => history.push("/forgot")}
              >
                {t("tr_forgot_password")}
              </Button>
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
