import Cookies from "js-cookie";

export const setCookie = (key, value, expire = {}) => {
  Cookies.set(key, value, expire);
};

export const getCookie = (key) => {
  return Cookies.get(key);
};

export const removeCookie = (key) => {
  return Cookies.remove(key);
};
