import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import StockCheck from "../pages/stockcheck/StockCheck";
import ReturnIOL from "../pages/return/ReturnIOL";

// context
import { useSelector } from "react-redux";
import Reset from "../pages/login/Reset";
import Forgot from "../pages/login/Forgot";
import TodoActionPlans from "../pages/todo/TodoActionPlans";
import ContactUS from "../pages/login/ContactUS";


export default function App() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  // global

  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/app/region" />;
          }}
        />

        <PrivateRoute path="/app" component={Layout} />
        <PrivateRoute path="/stockcheck" component={StockCheck} />
        <PrivateRoute path="/todoactions" component={TodoActionPlans} />
        <PrivateRoute path="/return" component={ReturnIOL} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/reset" component={Reset} />
        <PublicRoute path="/forgot" component={Forgot} />
        <PublicRoute path="/support" component={ContactUS} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
