import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

// components
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../services/http-common";
import {
  ROLE_MID,
  STATUS_CONFIRMED,
  STATUS_CONFIRMED_STR,
  STATUS_MISSED,
  STATUS_MISSED_STR,
  STATUS_NEW,
  STATUS_NEW_STR,
  STATUS_UNKNOWN_STR,
} from "../../constant/constants";
import CustomLoadingView from "../../components/LoadingView/CustomLoadingView";

import qr_stockcheck from "./StockCheck.png";
import qr_normal from "./Normal.png";
import { useTranslation } from "react-i18next";

export default function StockCheck() {
  const { t, i18n } = useTranslation();
  const locale = useSelector((state) => state.user.locale);
  const regionName = useSelector((state) => state.user.regionName);
  const countryName = useSelector((state) => state.user.countryName);
  const entityName = useSelector((state) => state.user.entityName);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const entity = useSelector((state) => state.user.entity);
  const role = useSelector((state) => state.user.role);

  const [open, setOpen] = useState(true);
  const [fopen, setFinishOpen] = useState(false);
  const [checkResults, setCheckResults] = useState([]);
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (role > ROLE_MID) history.goBack();
  }, [role, history]);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .post(`/check/${region}/${country}/entity/${entity}/stock_check_status`)
        .then((resp) => {
          setRecords(resp.data?.rows);
          setIsLoading(false);
          setCheckResults(
            resp.data?.rows?.map((row) => {
              let status = STATUS_UNKNOWN_STR;
              if (row.status === STATUS_MISSED) {
                status = STATUS_MISSED_STR;
              } else if (row.status === STATUS_CONFIRMED) {
                status = STATUS_CONFIRMED_STR;
              } else if (row.status === STATUS_NEW) {
                status = STATUS_NEW_STR;
              }

              return [
                row.serial,
                row.model,
                row.diopter,
                row.expiredate,
                status,
              ];
            })
          );
        });
    }, 2000); // Send request every 2 seconds

    return () => {
      clearInterval(intervalId); // Cleanup the interval when the component unmounts
    };
  }, [region, country, entity]);

  const handleDeleteRow = (dataIndex) => {
    setIsLoading(true);
    console.log(records);
    axios
      .delete(`/stockcheck/${records[dataIndex].id}`)
      .then((resp) => {
        console.log(resp);
        setIsLoading(false);
      }).catch(e => {
        console.log(e);
        setIsLoading(false)
      });
  };

  const handleDisagree = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setIsLoading(true);
    axios
      .post(`/check/${region}/${country}/entity/${entity}/stock_check_ready`)
      .then(() => {
        setOpen(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleDisagreeOnFinish = () => {
    setFinishOpen(false);
  };

  const handleAgreeOnFinish = () => {
    axios
      .post(`/check/${region}/${country}/entity/${entity}/stock_check_finish`)
      .then(() => {
        history.goBack();
        setIsLoading(false);
      }).catch(e => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ m: 5 }}>
      {isLoading && <CustomLoadingView />}
      <Dialog
        open={open}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("tr_would_you_like_to_initiate_a_new_count_or_resume_the_previous_one")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h2>{t("tr_scan_this_qr_code_to_switch_into_the_counting_mode")}</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={qr_stockcheck} width={200} alt="" />
            </div>
            <br />
            {t("tr_scan_this_qr_code_to_switch_into_the_counting_mode")}
            <br />
            {t("tr_choose_resume_to_return_to_the_last_procedure")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} autoFocus>
            {t("tr_resume")}
          </Button>
          <Button onClick={handleAgree}>{t("tr_new")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fopen}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("tr_do_you_want_to_finish_stock_check")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h2>{t("tr_switch_scanner_back_to_the_normal_operation_mode")}</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={qr_normal} width={200} alt="" />
            </div>
            <br />
            {t("tr_please_confirm_your_stock_checklist")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagreeOnFinish} autoFocus>
            {t("tr_disagree")}
          </Button>
          <Button onClick={handleAgreeOnFinish}>{t("tr_agree")}</Button>
        </DialogActions>
      </Dialog>
      <PageTitle
        region={regionName}
        country={countryName}
        entity={entityName}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setFinishOpen(true);
            }}
          >
            {t("tr_finish")}
          </Button>
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            title={t("tr_stock_check_list")}
            data={checkResults}
            columns={[
              t("tr_serial_number"),
              t("tr_model"),
              t("tr_diopter"),
              t("tr_expiry_date"),
              t("tr_status"),
              {
                name: t("tr_actions"),
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  textLabels: {
                    body: {
                      noMatch: t("tr_sorry_no_matching_records_found"),
                    },
                    pagination: {
                      rowsPerPage: t("tr_rows_per_page"),
                    },
                  },
                  customBodyRenderLite: (dataIndex) => {
                    return (
                      <Popconfirm
                        title="Delete the scan"
                        description="Are you sure to delete this scan?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteRow(dataIndex)}
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      >
                        <IconButton><DeleteIcon color="error" /></IconButton>
                      </Popconfirm>
                    );
                  }
                }
              },
            ]}
            options={{
              selectableRows: "none",
              setRowProps: (row) => {
                if (row[4] === STATUS_MISSED_STR) {
                  return {
                    style: { background: "#ED7B7B" },
                  };
                } else if (row[4] === STATUS_CONFIRMED_STR) {
                  return {
                    style: { background: "#85E6C5" },
                  };
                } else if (row[4] === STATUS_NEW_STR) {
                  return {
                    style: { background: "#F6F4EB" },
                  };
                } else if (row[4] === STATUS_UNKNOWN_STR) {
                  return {
                    style: { background: "#FD8D14" },
                  };
                }
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
