import React, { useEffect, useState } from "react";
import { DatePicker, Select, Radio, Space, Tag, Empty } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import mock from "./mock";
import { useSelector, useDispatch } from "react-redux";
import {
  setEntity,
  setMonthFilter,
  setRCountry,
  setRangeFilter,
  setRegion,
} from "../../redux/reducers";
import { Divider } from 'antd';
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROLE_ADMIN, ROLE_MAIN } from "../../constant/constants";
import axios from "../../services/http-common";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";

export default function BasicDateRangePickerComponent() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const region = useSelector((state) => state.user.region);
  const regionName = useSelector((state) => state.user.regionName);
  const countryName = useSelector((state) => state.user.countryName);
  const fmonths = useSelector((state) => state.user.fmonths);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);

  // useState
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regionVal, setRegionVal] = useState();
  const [countryVal, setCountryVal] = useState();

  const getCountries = (regionid) => {
    if (regionid == undefined)
      return;

    axios
      .get(`/country?filter={"regionid":${regionid}}`)
      .then((resp) => {
        setCountries(
          resp.data.map((contry) => {
            return { value: contry.id, label: contry.name };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        //
      });
  };

  useEffect(() => {
    axios
      .get("/region")
      .then((resp) => {
        if (!isEmpty(resp.data)) {
          setRegions(
            resp.data.map((region) => {
              return { value: region.id, label: region.name };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        //
      });
  }, []);

  useEffect(() => {
    getCountries(region);
  }, [region]);

  useEffect(() => {
    if (!isEmpty(regions)) {
      setRegionVal(regionName);
    }
  }, [regions, regionName]);

  useEffect(() => {
    if (!isEmpty(countries)) {
      setCountryVal(countryName);
    } else {
      setCountryVal();
    }
  }, [countries, countryName]);

  const handleMonthFilterChange = (e) => {
    dispatch(setMonthFilter(e.target.value));
  };

  const handleRangePickerOnchange = (ranges) => {
    if (!isEmpty(ranges)) {
      dispatch(
        setRangeFilter({
          start: ranges[0].format(dateFormat),
          end: ranges[1].format(dateFormat),
        })
      );
      dispatch(setMonthFilter("0"));
    }
  };
  useEffect(() => {
    if (isEmpty(fmonths) || fmonths !== "0") {
      let endDate = dayjs();
      let startDate = endDate.subtract(fmonths, "month");

      if (isEmpty(fmonths)) {
        dispatch(setMonthFilter("12"));
        startDate = endDate.subtract("12", "month");
      }

      dispatch(
        setRangeFilter({
          start: startDate.format(dateFormat),
          end: endDate.format(dateFormat),
        })
      );
    }
  }, [fmonths, dispatch]);

  const handleRegionSelect = (selectedRegion) => {
    dispatch(setRegion(selectedRegion));
    dispatch(setRCountry());
    dispatch(setEntity());
    setRegionVal(selectedRegion.value);
    getCountries(selectedRegion.value);

    history.push("/app/region");
  };

  const handleCountrySelect = (selectedCountry) => {
    dispatch(setRCountry(selectedCountry));
    dispatch(setEntity());
    setCountryVal(selectedCountry.value);

    history.push("/app/country");
  };

  return (
    <>
      <Box
        height={"inherit"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box>
          <Box m={1} justifyContent={"center"} display={"none"}>
            <Radio.Group value={fmonths} onChange={handleMonthFilterChange}>
              <Radio.Button value="12">12 Ms</Radio.Button>
              <Radio.Button value="3">3 Ms</Radio.Button>
              <Radio.Button value="1">1 M</Radio.Button>
            </Radio.Group>
          </Box>
          <Box m={1} display={"none"}>
            <RangePicker
              size="large"
              value={[dayjs(startDate), dayjs(endDate)]}
              onChange={handleRangePickerOnchange}
              format={dateFormat}
            />
          </Box>
          {role <= ROLE_MAIN && role >= ROLE_ADMIN && (
            <>
              <Divider>{t("tr_region_&_country")}</Divider>
              <Box m={1} justifyContent={"center"} display={"flex"}>
                <Select
                  style={{
                    width: 200,
                  }}
                  placeholder={t("tr_search_to_region")}
                  value={regionVal}
                  onSelect={(region) => {
                    handleRegionSelect(region);
                  }}
                  options={regions}
                  labelInValue
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Region"
                    />
                  }
                />
              </Box>
              <Box m={1} justifyContent={"center"} display={"flex"}>
                <Select
                  style={{
                    width: 200,
                  }}
                  placeholder={t("tr_search_to_country")}
                  value={countryVal}
                  labelInValue
                  options={countries}
                  onSelect={handleCountrySelect}
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Country"
                    />
                  }
                />
              </Box>
            </>
          )}
        </Box>

        <Box
          m={1}
          justifyContent={"center"}
          alignItems={"center"}
          display={"none"}
        >
          <Space size={[0, 8]} wrap style={{ justifyContent: "center" }}>
            {mock.tags.map((tag) => (
              <Tag closeIcon={<CloseCircleOutlined />} key={tag}>
                <a href="/">{tag}</a>
              </Tag>
            ))}
          </Space>
        </Box>
      </Box>
    </>
  );
}
