import { isEmpty } from "lodash";
import moment from "moment";
const { differenceInDays } = require("date-fns");

export const convertToFixed = (value, c = 2) => {
  if (value != null) return value.toFixed(2);
  else return 0;
};

export const convertToLocalTime = (param) => {
  if (!isEmpty(param) != null) {
    var date = new Date(param);

    var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    var localTime = date.getTime() + timezoneOffset;
    var localDate = new Date(localTime);
    return localDate;
  } else return new Date();
};

export const calculateDiffDays = (start, end) => {
  const diffInDays = differenceInDays(end, start);
  return diffInDays;
};

export const calculateDiffWeeks = (createdAt) => {
  var diffWeeks = 0;

  const entityCreatedAt = moment(createdAt);
  const currentDate = moment();
  diffWeeks = currentDate.diff(entityCreatedAt, 'weeks');

  return diffWeeks;
};

export const getYearAndWeekNumber = (createdAt) => {
  const entityCreatedAt = moment(createdAt);
  const weekNumber = entityCreatedAt.week();
  const year = entityCreatedAt.weekYear();

  return { year, weekNumber };
};

export const getYearAndWeekNumberAfterWeeks = (createdAt, weeks) => {
  const entityCreatedAt = moment(createdAt).add(weeks, "weeks");
  const weekNumber = entityCreatedAt.week();
  const year = entityCreatedAt.weekYear();

  return { year, weekNumber };
};

export const getStartDateFromEntityDateAfterWeeks = (createdAt, weeks) => {
  return moment(createdAt).add(weeks, "weeks").startOf("week").format("YYYY-MM-DD");
};

export const getEndDateFromEntityDateAfterWeeks = (createdAt, weeks) => {
  return moment(createdAt).add(weeks, "weeks").endOf("week").format("YYYY-MM-DD");
};

export const getFormattedTodayDateString = () => {
  return moment().format("YYYY-MM-DD");
};

