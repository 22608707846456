import axios, { setAuthToken } from "../services/http-common";
import {
  ROLE_ADMIN,
  ROLE_MAIN,
  ROLE_MID,
  ROLE_NUR,
} from "../constant/constants";
import { isEmpty } from "lodash";
import {
  resetState,
  setEntity,
  setHasManagerView,
  setIsAunthenticated,
  setNode,
  setRCountry,
  setRegion,
  setRole,
} from "../redux/reducers";

export { loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    const params = { username: login, password };

    axios
      .post("/user/login", params)
      .then((response) => {
        setError(false);
        setIsLoading(false);
        // set login data
        if (!isEmpty(response.data)) {
          let info = response.data;

          resetStore(dispatch);
          setLoginInfoToStore(dispatch, info);
          if (info.role === ROLE_MID) {
            history.push("/app/country");
          } else if (info.role === ROLE_NUR) {
            history.push("/app/entity");
          } else {
            history.push("/");
          }
        }
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);
        console.log(error);
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function setLoginInfoToStore(dispatch, info) {
  let userInfo = {};

  dispatch(setIsAunthenticated(true));
  userInfo.role = info.role;
  dispatch(setRole(userInfo.role));
  userInfo.hasmanagerviewpem = info.hasmanagerviewpem;
  dispatch(setHasManagerView(userInfo.hasmanagerviewpem));

  if (userInfo.role === ROLE_MID) {
    userInfo.node = info.node?.id;
    userInfo.nodeName = info.node?.name;
    dispatch(setNode({ value: userInfo.node, label: userInfo.nodeName }));
    userInfo.country = info.node?.countryid;
    userInfo.region = info.node?.country?.regionid;
    userInfo.countryName = info.node?.country?.name;
    userInfo.regionName = info.node?.country?.region?.name;
    dispatch(setRegion({ value: userInfo.region, label: userInfo.regionName }));
    dispatch(
      setRCountry({ value: userInfo.country, label: userInfo.countryName })
    );
  } else if (userInfo.role === ROLE_MAIN || userInfo.role === ROLE_ADMIN) {
    userInfo.region = info.entities[0]?.country?.regionid;
    userInfo.regionName = info.entities[0]?.country?.region?.name;
    dispatch(setRegion({ value: userInfo.region, label: userInfo.regionName }));
  } else if (userInfo.role === ROLE_NUR) {
    userInfo.country = info.entities[0]?.country?.id;
    userInfo.countryName = info.entities[0]?.country?.name;
    userInfo.region = info.entities[0]?.country?.regionid;
    userInfo.regionName = info.entities[0]?.country?.region?.name;
    dispatch(setRegion({ value: userInfo.region, label: userInfo.regionName }));
    dispatch(
      setRCountry({ value: userInfo.country, label: userInfo.countryName })
    );
    userInfo.entity = info.entities[0]?.id;
    userInfo.entityName = info.entities[0]?.name;
    dispatch(
      setEntity({ entity: userInfo.entity, entityName: userInfo.entityName })
    );
  }
  storeLocalStorage(userInfo);
}

function resetStore(dispatch) {
  dispatch(resetState());
  resetLocalStorage();
}

function signOut(dispatch, history) {
  dispatch(setIsAunthenticated(false));
  setAuthToken("");
  resetStore(dispatch);
}

function storeLocalStorage(userInfo) {
  if (!isEmpty(userInfo.role)) localStorage.setItem("role", userInfo.role);
  if (!isEmpty(userInfo.region))
    localStorage.setItem("region", userInfo.region);
  if (!isEmpty(userInfo.entity))
    localStorage.setItem("entity", userInfo.entity);
  if (!isEmpty(userInfo.country))
    localStorage.setItem("country", userInfo.country);
  if (!isEmpty(userInfo.node)) localStorage.setItem("node", userInfo.node);
  if (!isEmpty(userInfo.regionName))
    localStorage.setItem("regionName", userInfo.regionName);
  if (!isEmpty(userInfo.entityName))
    localStorage.setItem("entityName", userInfo.entityName);
  if (!isEmpty(userInfo.countryName))
    localStorage.setItem("countryName", userInfo.countryName);
  if (!isEmpty(userInfo.nodeName))
    localStorage.setItem("nodeName", userInfo.nodeName);
  if (!isEmpty(userInfo.hasmanagerviewpem))
    localStorage.setItem("hasmanagerviewpem", userInfo.hasmanagerviewpem);
}

function resetLocalStorage() {
  localStorage.removeItem("role");
  localStorage.removeItem("region");
  localStorage.removeItem("entity");
  localStorage.removeItem("country");
  localStorage.removeItem("node");
  localStorage.removeItem("regionName");
  localStorage.removeItem("entityName");
  localStorage.removeItem("countryName");
  localStorage.removeItem("nodeName");
  localStorage.removeItem("fmonths");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  localStorage.removeItem("model");
  localStorage.removeItem("isManagerMode");
  localStorage.removeItem("brandName");
  localStorage.removeItem("brand");
  localStorage.removeItem("hasmanagerviewpem");
}
