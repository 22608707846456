import React, { useEffect, useState } from "react";
import axios from "../../services/http-common";

import { useSelector } from "react-redux";
import "./custom.css";

import HeatMapDate from "react-d3-heatmap";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import useScreenSize from "../../hooks/useScreenSize";
import { convertToLocalTime } from "../../services/convert";
import { useTranslation } from "react-i18next";

const colors = [];
colors.push({ count: 1, color: "#F6E1B5" });
colors.push({ count: 2, color: "#E2BF81" });
colors.push({ count: 3, color: "#B21E4B" });
colors.push({ count: 4, color: "#3C1B1F" });

export default function CheckOutCalender() {
  const { t } = useTranslation();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const entity = useSelector((state) => state.user.entity);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);
  const screenSize = useScreenSize();

  const [values, setValues] = useState([]);
  const [years, setYears] = useState([new Date().getFullYear()]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [rectWidth, setRectWidth] = useState(10);

  useEffect(() => {
    if (!isEmpty(screenSize)) {
      if (screenSize.width < 1300) {
        setRectWidth(8);
      } else if (screenSize.width < 1400) {
        setRectWidth(11);
      } else if (screenSize.width < 1500) {
        setRectWidth(14);
      } else if (screenSize.width < 1700) {
        setRectWidth(17);
      } else {
        setRectWidth(20);
      }
    }
  }, [screenSize]);

  useEffect(() => {
    var filter = { country, entity, startDate, endDate, model, brand };
    if (region == undefined) {
      return;
    }

    axios
      .post(`/view/${region}/getRegionChekoutStateByDate`, filter)
      .then((response) => {
        const data = [];
        const yrs = [];

        response.data.map((value) => {
          data.push({
            date: convertToLocalTime(value.date),
            count: parseInt(value.checkout_count),
          });
          let year = convertToLocalTime(value.date).getFullYear();
          if (yrs.indexOf(year) < 0) yrs.push(year);
          return value;
        });
        if (!isEmpty(yrs)) setYears(yrs);
        else setYears([new Date().getFullYear()]);
        setValues(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, country, startDate, endDate, model, entity, brand]);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, [years]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box sx={{ minWidth: 120, m: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{t("tr_year")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            label={t("tr_year")}
            onChange={handleYearChange}
          >
            {years.map((yr) => (
              <MenuItem value={yr}>{yr}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <HeatMapDate
        startDate={convertToLocalTime(`${year}-01-01T12:00:00`)}
        endDate={convertToLocalTime(`${year}-12-31T12:00:00`)}
        data={values}
        colors={colors}
        defaultColor={"#F1F6F9"}
        // textDefaultColor={textDefaultColor}
        rectWidth={rectWidth}
      // marginBottom={marginBottom}
      // marginRight={marginRight}
      // displayYear={displayYear}
      // backgroundColor={backgroundColor}
      // textColor={textColor}
      // radius={radius}
      // classnames={classnames.join(" ")}
      // onClick={onClick}
      // onMouseEnter={onMouseEnter}
      // onMouseLeave={onMouseLeave}
      // shouldStartMonday={shouldStartMonday}
      // monthSpace={monthSpace}
      // rangeDisplayData={rangeDisplayData}
      />
    </Box>
  );
}
