import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { Switch } from 'antd';

import classNames from "classnames";
import "flag-icons/css/flag-icons.min.css";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { ROLE_ADMIN, ROLE_MAIN, ROLE_MID, languages } from "../../constant/constants";
import { signOut } from "../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setLocale, setMode } from "../../redux/reducers";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  var classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isManagerMode = useSelector((state) => state.user.isManagerMode);
  const role = useSelector((state) => state.user.role);
  const hasmanagerviewpem = useSelector((state) => state.user.hasmanagerviewpem);
  const locale = useSelector((state) => state.user.locale);
  const { t, i18n } = useTranslation();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [languageMenu, setLanguageMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  const [languae, setLanguae] = useState("gb");

  useEffect(() => {
    setLanguae(locale == "en" ? "gb" : locale);
    i18n.changeLanguage(locale);
  }, [locale])

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
          style={{ display: "none" }}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          e<span style={{ fontFamily: "DinProBold" }}>Consignment</span>
        </Typography>
        <div className={classes.grow} />
        {(role == ROLE_ADMIN || role == ROLE_MAIN || (role == ROLE_MID && hasmanagerviewpem == true)) &&
          <Switch checkedChildren={t("tr_manager")} unCheckedChildren={t("tr_user")} checked={isManagerMode} onChange={(e) => { dispatch(setMode(e)); }}
          />}

        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="language-menu"
          onClick={(e) => {
            setLanguageMenu(e.currentTarget);
          }}
          className={classes.headerMenuButton}
        >
          <span
            class={`fi fi-${languae} fis`}
            style={{ borderRadius: 20 }}
          ></span>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="language-menu"
          open={Boolean(languageMenu)}
          anchorEl={languageMenu}
          onClose={() => setLanguageMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          {languages.map((language) => (
            <MenuItem
              key={language.value}
              className={classes.messageNotification}
              onClick={(e) => {
                setLanguae(language.icon);
                dispatch(setLocale(language.value));
                setLanguageMenu(null);
              }}
            >
              <div className={classes.messageNotificationSide}>
                <span class={`fi fi-${language.icon} fis`}></span>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide
                )}
              >
                <Typography weight="medium">{language.label}</Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          {(role == ROLE_ADMIN || role == ROLE_MAIN || role == ROLE_MID) &&
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => {
                  history.push('/todoactions');
                }}
              >
                {t("tr_to_do")}
              </Typography>
            </div>
          }
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {
                signOut(dispatch, history);
              }}
            >
              {t("tr_sign_out")}
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
