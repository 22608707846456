import React, { useEffect, useState } from "react";
import { isArray } from "lodash";
import "./style.css";
const totalTitle = "Σ";

export default function ToricDistributionTable({ torics }) {
  const [column, setColumn] = useState([]);
  const [rows, setRows] = useState({});

  useEffect(() => {
    let sumOfRow = {};
    let header = [""];
    let rowHeader = [];
    let rows = {};
    let sumOfColumn = torics.map((toric) => {
      let sum = 0;
      for (const key in toric) {
        if (key !== "diopter") {
          sum += toric[key];

          if (isNaN(sumOfRow[key])) {
            sumOfRow[key] = 0;
          } else {
            sumOfRow[key] += toric[key];
          }

          rowHeader[key] = key;

          if (!isArray(rows[key])) {
            rows[key] = [];
            rows[key].push(toric[key]);
          } else {
            rows[key].push(toric[key]);
          }
        }
      }

      header.push(toric.diopter);

      return sum;
    });

    header.push(totalTitle);
    rowHeader[totalTitle] = totalTitle;

    var sumOfAll = 0;
    for (const key in sumOfRow) {
      rows[key].push(sumOfRow[key]);
      sumOfAll += sumOfRow[key];
    }
    rows[totalTitle] = sumOfColumn;
    rows[totalTitle].push(sumOfAll);

    setColumn(header);
    setRows(rows);
  }, [torics]);

  return (
    <div className="toric-table-content">
      <table className="toric-table">
        <thead className="toric-table-thead">
          <tr>
            {column.map((c) => (
              <th className="toric-table-th">{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(rows).map((key) => (
            <tr key={key} className="toric-table-tr">
              <td className="toric-table-td-leader">{key}</td>
              {rows[key].map((value) => {
                const redColor = 125;
                const greenColor = 190;
                const blueColor = 130;
                const opacity = value * 0.2 + 0;
                return (
                  <td
                    className="toric-table-td"
                    style={{
                      background: `rgb(${redColor},
                           ${greenColor},
                           ${blueColor},
                        ${opacity})`,
                    }}
                  >
                    {value == 0 ? "" : value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
}
