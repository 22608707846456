import React, { useEffect, useState } from "react";
import axios from "../../services/http-common";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import ToricDistributionTable from "./table/ToricDistributionTable";
import { Segmented } from "antd";
import { useTranslation } from "react-i18next";

const GRAPH_SHOW = "Graph";
const TABLE_SHOW = "Table";

const getDefaultArray = () => {
  var start = 6;
  var end = 30;
  var idx = start;
  var arr = [];

  while (idx <= end) {
    arr.push({
      diopter: idx,
      T2: 0,
      T3: 0,
      T4: 0,
      T5: 0,
      T6: 0,
      T7: 0,
      T8: 0,
      T9: 0,
    });
    idx = idx + 0.5;
  }

  return arr;
};

export default function ToricDistribution() {
  const { t } = useTranslation();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const entity = useSelector((state) => state.user.entity);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);

  const [torics, setTorics] = useState([]);
  const [showTS, setShowTS] = useState({
    T2: true,
    T3: true,
    T4: true,
    T5: true,
    T6: true,
    T7: true,
    T8: true,
    T9: true,
  });
  const [show, setShow] = useState(GRAPH_SHOW);

  const toggleLine = (lineKey) => {
    setShowTS((prevLines) => ({
      ...prevLines,
      [lineKey]: !prevLines[lineKey], // invert the value of the line
    }));
  };

  useEffect(() => {
    var filter = { country, startDate, endDate, model, brand, entity };

    if (region == undefined)
      return;

    axios
      .post(`/view/${region}/getToricDistribution`, filter)
      .then((response) => {
        var arr = getDefaultArray();
        response.data.forEach((element) => {
          var idx = Math.ceil((parseFloat(element.diopter) - 6.0) / 0.5);

          if (idx < 0) {
            idx = 0;
          } else if (idx > arr.length - 1) {
            idx = arr.length - 1;
          }
          var tpower = parseInt(element.tpower);

          switch (tpower) {
            case 2:
              arr[idx].T2 += 1;
              break;
            case 3:
              arr[idx].T3 += 1;
              break;
            case 4:
              arr[idx].T4 += 1;
              break;
            case 5:
              arr[idx].T5 += 1;
              break;
            case 6:
              arr[idx].T6 += 1;
              break;
            case 7:
              arr[idx].T7 += 1;
              break;
            case 8:
              arr[idx].T8 += 1;
              break;
            case 9:
              arr[idx].T9 += 1;
              break;
            default:
              break;
          }
        });
        setTorics(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, country, startDate, endDate, model, brand, entity]);

  return (
    <div>
      {show === GRAPH_SHOW && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={torics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="diopter" />
            <YAxis />
            <Tooltip />
            <Legend onClick={({ dataKey }) => toggleLine(dataKey)} />
            <Line
              type="monotone"
              dataKey="T2"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              hide={!showTS.T2}
            />
            <Line
              type="monotone"
              dataKey="T3"
              stroke="#FF7F50"
              activeDot={{ r: 8 }}
              hide={!showTS.T3}
            />
            <Line
              type="monotone"
              dataKey="T4"
              stroke="#FF69B4"
              activeDot={{ r: 8 }}
              hide={!showTS.T4}
            />
            <Line
              type="monotone"
              dataKey="T5"
              stroke="#48D1CC"
              activeDot={{ r: 8 }}
              hide={!showTS.T5}
            />
            <Line
              type="monotone"
              dataKey="T6"
              stroke="#BA55D3"
              activeDot={{ r: 8 }}
              hide={!showTS.T6}
            />
            <Line
              type="monotone"
              dataKey="T7"
              stroke="#FFD700"
              activeDot={{ r: 8 }}
              hide={!showTS.T7}
            />
            <Line
              type="monotone"
              dataKey="T8"
              stroke="#FFA500"
              activeDot={{ r: 8 }}
              hide={!showTS.T8}
            />
            <Line
              type="monotone"
              dataKey="T9"
              stroke="#9370DB"
              activeDot={{ r: 8 }}
              hide={!showTS.T9}
            />
          </LineChart>
        </ResponsiveContainer>
      )}

      {show === TABLE_SHOW && <ToricDistributionTable torics={torics} />}
      <Segmented
        value={show}
        options={[
          {
            value: GRAPH_SHOW,
            label: t("tr_graph"),
          },
          {
            value: TABLE_SHOW,
            label: t("tr_table"),
          }
        ]}
        onChange={(v) => setShow(v)}
        style={{ marginTop: 30 }}
      />
    </div>
  );
}
