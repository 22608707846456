import React, { useEffect, useState } from "react";
import axios from "../../services/http-common";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { Segmented } from "antd";
import { useTranslation } from "react-i18next";

const BOTH_SHOW = "Both";
const ONLY_CHECKIN_SHOW = "Checkin";
const ONLY_CHECKOUT_SHOW = "Checkout";

const getDefaultArray = () => {
  var start = 6;
  var end = 30;
  var idx = start;
  var arr = [];

  while (idx <= end) {
    arr.push({
      diopter: idx,
      checkin: 0,
      checkout: 0,
    });
    idx = idx + 0.5;
  }

  return arr;
};

export default function BellCurveLineChart() {
  const { t } = useTranslation();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const entity = useSelector((state) => state.user.entity);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);
  const [bells, setBells] = useState([]);
  const [show, setShow] = useState(BOTH_SHOW);

  useEffect(() => {
    var filter = { country, startDate, endDate, model, entity, brand };
    if (region == undefined)
      return;
    axios
      .post(`/view/${region}/getBellStateByDiopter`, filter)
      .then((response) => {
        var arr = getDefaultArray();

        response.data.forEach((element) => {
          var idx = Math.ceil((parseFloat(element.diopter) - 6.0) / 0.5);

          if (idx < 0) {
            idx = 0;
          } else if (idx > arr.length - 1) {
            idx = arr.length - 1;
          }

          arr[idx].checkin = arr[idx].checkin + parseInt(element.checkin_count);
          arr[idx].checkout =
            arr[idx].checkout + parseInt(element.checkout_count);
        });

        setBells(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, country, startDate, endDate, model, brand, entity]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        {/* */}
        <LineChart
          width={500}
          height={300}
          data={bells}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="diopter" />
          <YAxis />
          <Tooltip />
          <Legend />
          {(show === BOTH_SHOW || show === ONLY_CHECKIN_SHOW) && (
            <Line
              name={t("tr_checkin")}
              type="monotone"
              dataKey="checkin"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          )}
          {(show === BOTH_SHOW || show === ONLY_CHECKOUT_SHOW) && (
            <Line
              name={t("tr_checkout")}
              type="monotone"
              dataKey="checkout"
              activeDot={{ r: 8 }}
              stroke="#82ca9d"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <Segmented
        value={show}
        options={[
          {
            value: BOTH_SHOW,
            label: BOTH_SHOW,
          },
          {
            value: ONLY_CHECKIN_SHOW,
            label: t("tr_checkin"),
          },
          {
            value: ONLY_CHECKOUT_SHOW,
            label: t("tr_checkout"),
          }
        ]}
        onChange={(v) => setShow(v)}
        style={{ marginTop: 30 }}
      />
    </div>
  );
}
