import axios from "axios";
import { isEmpty } from "lodash";
import { getCookie, removeCookie, setCookie } from "./cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, // Your API base URL
});

// Add a request interceptor
instance.interceptors.request.use(
  (req) => {
    // Get the JWT token from local storage or Redux store
    const token = getCookie("token");

    // Add the token to the request header
    if (!isEmpty(token)) req.headers.Authorization = `Bearer ${token}`;

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (resp) => {
    try {
      let token = resp.data.token;

      if (token) {
        setCookie("token", token, { expires: 1 / 24 });
        setAuthToken(token);
      }

      return resp;
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    removeCookie("token");
  }
};

export default instance;
