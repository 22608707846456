import { Box, Button, Grid, MenuItem, Select, makeStyles } from '@material-ui/core';
import { Radio } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import axios from "../../services/http-common";
import { calculateDiffWeeks, getEndDateFromEntityDateAfterWeeks, getStartDateFromEntityDateAfterWeeks, getYearAndWeekNumber, getYearAndWeekNumberAfterWeeks } from '../../services/convert';
import { isEmpty } from 'lodash';
import defaultTheme from '../../themes/default';
import CustomLoadingView from "../../components/LoadingView/CustomLoadingView";
import FullFeaturedCrudGrid from "../../components/DataGrid/FullFeaturedCrudGrid";
import "./style.css";
import { evalutionStateStr } from '../../constant/constants';
import { useTranslation } from "react-i18next";

const quarterRange = [
    { number: 1, start: 1, end: 13 },
    { number: 2, start: 14, end: 26 },
    { number: 3, start: 27, end: 39 },
    { number: 4, start: 40, end: 52 }
];

const quarterState = {
    NOT_STARTED: { theme: "default", color: "", title: "Not Started", disabled: true },
    IN_PROGRESS: { theme: "second", color: defaultTheme.palette.warning.main, title: "In Progress", disabled: false },
    VIEW: { theme: "primary", color: defaultTheme.palette.primary.main, title: "View", disabled: false },
};

const modelGroups = {
    All: { title: 'All', turnrate: 0 },
    Monofocal: { title: 'Monofocal', turnrate: 4 },
    Toric: { title: 'Toric', turnrate: 1.7 },
    Multifocal: { title: 'Multifocal', turnrate: 2.5 },
};

const summaryFilterOptions = [
    {
        label: "tr_all",
        value: modelGroups.All.title,
    },
    {
        label: "tr_monofocal",
        value: modelGroups.Monofocal.title,
        disabled: true
    },
    {
        label: "tr_toric",
        value: modelGroups.Toric.title,
        disabled: true
    },
    {
        label: "tr_multifocal",
        value: modelGroups.Multifocal.title,
        disabled: true
    },
];

const totalTitle = "Total";

export default function EntityManager() {
    const { t } = useTranslation();
    const defaultSummaryRows = {
        forecastUnits: ["Forecast"],
        forecastToTarget: ["Forecast to target, %"],
        week52TargetUnits: ["52-week target"],
        week13TargetUnits: ["QTD target"],
        week52Turnrate: ["52-week Target turnrate"],
        xWeekAchievementUnits: ["QTD progress"],
        xWeekAchievementRate: ["QTD progress, %"],
        YTDAchievementUnits: ["YTD progress"],
        quarterlyRunrate: ["QTD forecast"],
        avgStockSize: ["Average stock size"],
    };
    const localeTable = {
        forecastUnits: "tr_forecast",
        forecastToTarget: "tr_forecast_to_target",
        avgStockSize: "tr_average_stock_size",
        YTDAchievementUnits: "tr_ytd_progress",
        week52TargetUnits: "tr_week_target",
        week13TargetUnits: "tr_qtd_target",
        week52Turnrate: "tr_week_target_turnrate",
        xWeekAchievementUnits: "tr_qtd_progress",
        xWeekAchievementRate: "tr_qtd_progress_p",
        quarterlyRunrate: "tr_qtd_forecast",
    };
    const localeProgress = {
        NOT_STARTED: "tr_not_started",
        IN_PROGRESS: "tr_in_progress",
        VIEW: "tr_view"

    }
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const view = queryParams.get('view');

    const locale = useSelector((state) => state.user.locale);
    const entityName = useSelector((state) => state.user.entityName);
    const entity = useSelector((state) => state.user.entity);
    const [entityInfo, setEntityInfo] = useState({});
    const [years, setYears] = useState([]);
    const [currentQuarterNumber, setCurrentQuarterNumber] = useState(1);
    const [currentWeekNumber, setCurrentWeekNumber] = useState(1);
    const [reportWeekNumber, setReportWeekNumber] = useState(1);
    const [reportCurrentWeekNumber, setReportCurrentWeekNumber] = useState(1);
    const [remainingWeekNumber, setRemainingWeekNumber] = useState(1);
    const [currentYear, setCurrentYear] = useState(1);
    const [selectedYear, setSelectedYear] = useState(1);
    const [entityYear, setEntityYear] = useState(1);
    // const [entityOpenDate, setEntityOpenDate] = useState("");
    const [entityStartCount, setEntityStartCount] = useState(0);
    const [entityStartCountModelTable, setEntityStartCountModelTable] = useState({});
    const [evalutionState, setEvalutionState] = useState(evalutionStateStr.below);
    const [summaryAll, setSummaryAll] = useState([]);
    const [summaryColumn, setSummaryColumn] = useState([]);
    const [summaryRows, setSummaryRows] = useState(defaultSummaryRows);
    const [summaryFilterValue, setTableFilterValue] = useState(summaryFilterOptions[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [isActionPlanView, setIsActionPlanView] = useState(false);
    const [validSummaryFilterOptions, setValidSummaryFilterOptions] = useState(summaryFilterOptions);

    const useStyles = makeStyles((theme) => ({
        alignCenter: {
            display: "flex",
            justifyContent: "center",
        },

        toolbar: theme.mixins.toolbar,
    }));

    const self_classes = useStyles();

    useEffect(() => {
        if (view == "actions") {
            setIsActionPlanView(true);
        }
    }, [view]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`/stocksize/${entity}`)
            .then((response) => {
                var t = {};
                var sumOfStockSize = 0;
                response.data.map((record) => {
                    t[record.model] = record.stocksize;
                    sumOfStockSize += record.stocksize;
                    return record;
                });

                setEntityStartCount(sumOfStockSize);
                setEntityStartCountModelTable(t);
                axios
                    .get(`/entityinfo/${entity}`)
                    .then((response) => {
                        setIsLoading(false);
                        var entityInfo = response.data;

                        setEntityInfo(entityInfo);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [entity]);

    useEffect(() => {
        if (!isEmpty(entityInfo)) {
            var createdat = entityInfo.createdat;
            var yearOffset = 0;
            var diffWeeks = calculateDiffWeeks(createdat);
            const entityDate = getYearAndWeekNumber(createdat);

            yearOffset = parseInt(diffWeeks / 52);
            var weekOffset = diffWeeks % 52;
            var yearArr = [];
            for (var i = 0; i <= yearOffset; i++) {
                yearArr.push(entityDate.year + i);
            }

            setYears(yearArr);
            setCurrentYear(entityDate.year + yearOffset);
            setSelectedYear(entityDate.year + yearOffset);
            setCurrentWeekNumber(weekOffset + 1);
            setEntityYear(entityDate.year);
        }
    }, [entityInfo]);

    useEffect(() => {
        if (isEmpty(summaryAll))
            return;
        var filterTurnRate = modelGroups[summaryFilterValue].turnrate;
        var hasSubTotal = filterTurnRate != 0;
        var header = ["", totalTitle];
        var rows = defaultSummaryRows;

        rows.avgStockSize.push(0);
        rows.forecastUnits.push(0);
        rows.forecastToTarget.push(0);
        rows.week52TargetUnits.push(0);
        rows.week13TargetUnits.push(0);
        rows.week52Turnrate.push("");
        rows.xWeekAchievementUnits.push(0);
        rows.xWeekAchievementRate.push(0);
        rows.YTDAchievementUnits.push(0);
        rows.quarterlyRunrate.push(0);

        if (hasSubTotal) {
            header.push(`Total by ${summaryFilterValue}`);
            rows.avgStockSize.push(0);
            rows.forecastUnits.push(0);
            rows.forecastToTarget.push(0);
            rows.week52TargetUnits.push(0);
            rows.week13TargetUnits.push(0);
            rows.week52Turnrate.push("");
            rows.xWeekAchievementUnits.push(0);
            rows.xWeekAchievementRate.push(0);
            rows.YTDAchievementUnits.push(0);
            rows.quarterlyRunrate.push(0);
        }

        for (const key in summaryAll) {
            var turnrate = summaryAll[key].turnrate;
            var isFiltered = !hasSubTotal || turnrate == filterTurnRate;

            isFiltered && header.push(key);

            var avgStockSize = parseInt(Math.round(summaryAll[key].checkin / reportWeekNumber));
            isFiltered && rows.avgStockSize.push(avgStockSize);
            (isFiltered && hasSubTotal) && (rows.avgStockSize[2] += avgStockSize);
            rows.avgStockSize[1] += avgStockSize; // for total

            isFiltered && rows.week52Turnrate.push(turnrate);

            var startingStockSize = entityStartCountModelTable[key] == undefined ? 0 : entityStartCountModelTable[key];

            var week52TargetUnits = parseInt(Math.round(startingStockSize * summaryAll[key].turnrate));
            isFiltered && rows.week52TargetUnits.push(week52TargetUnits);
            (isFiltered && hasSubTotal) && (rows.week52TargetUnits[2] += week52TargetUnits);
            rows.week52TargetUnits[1] += week52TargetUnits;

            var week13TargetUnits = parseInt(Math.round(week52TargetUnits / 4));
            isFiltered && rows.week13TargetUnits.push(week13TargetUnits);
            (isFiltered && hasSubTotal) && (rows.week13TargetUnits[2] += week13TargetUnits);
            rows.week13TargetUnits[1] += week13TargetUnits;

            var YTDAchievementUnits = summaryAll[key].YTD;
            isFiltered && rows.YTDAchievementUnits.push(YTDAchievementUnits);
            (isFiltered && hasSubTotal) && (rows.YTDAchievementUnits[2] += YTDAchievementUnits);
            rows.YTDAchievementUnits[1] += YTDAchievementUnits;

            var forecastUnits = parseInt(YTDAchievementUnits / reportWeekNumber * remainingWeekNumber + YTDAchievementUnits);
            isFiltered && rows.forecastUnits.push(forecastUnits);
            (isFiltered && hasSubTotal) && (rows.forecastUnits[2] += forecastUnits);
            rows.forecastUnits[1] += forecastUnits;

            var forecastToTarget = parseInt(forecastUnits / week52TargetUnits * 100);
            isFiltered && rows.forecastToTarget.push(`${forecastToTarget}%`);

            var xWeekAchievementUnits = summaryAll[key].checkout;
            isFiltered && rows.xWeekAchievementUnits.push(xWeekAchievementUnits);
            (isFiltered && hasSubTotal) && (rows.xWeekAchievementUnits[2] += xWeekAchievementUnits);
            rows.xWeekAchievementUnits[1] += xWeekAchievementUnits;

            var xWeekAchievementRate = parseInt(xWeekAchievementUnits / week13TargetUnits * 100);
            isFiltered && rows.xWeekAchievementRate.push(`${xWeekAchievementRate}%`);

            var quarterlyRunrate = parseFloat(xWeekAchievementUnits / reportCurrentWeekNumber * remainingWeekNumber + xWeekAchievementUnits).toFixed(2);
            isFiltered && rows.quarterlyRunrate.push(quarterlyRunrate);
            isFiltered && (rows.quarterlyRunrate[2] = (parseFloat(rows.quarterlyRunrate[2]) + parseFloat(quarterlyRunrate)).toFixed(2));
            rows.quarterlyRunrate[1] = (parseFloat(rows.quarterlyRunrate[1]) + parseFloat(quarterlyRunrate)).toFixed(2);
        }
        var foreCastToTargetTotal = parseInt(rows.forecastUnits[1] / rows.week52TargetUnits[1] * 100);

        if (foreCastToTargetTotal < 95) {
            setEvalutionState(evalutionStateStr.below);
        } else if (foreCastToTargetTotal >= 95 && foreCastToTargetTotal <= 105) {
            setEvalutionState(evalutionStateStr.onpar);
        } else {
            setEvalutionState(evalutionStateStr.exceeding);
        }
        rows.forecastToTarget[1] = `${foreCastToTargetTotal}%`;
        rows.xWeekAchievementRate[1] = `${parseInt(rows.xWeekAchievementUnits[1] / rows.week13TargetUnits[1] * 100)}%`;

        if (hasSubTotal) {
            rows.forecastToTarget[2] = `${parseInt(rows.forecastUnits[2] / rows.week52TargetUnits[2] * 100)}%`;
            rows.xWeekAchievementRate[2] = `${parseInt(rows.xWeekAchievementUnits[2] / rows.week13TargetUnits[2] * 100)}%`;
        }

        setSummaryColumn(header);
        setSummaryRows(rows);
    }, [summaryAll, reportCurrentWeekNumber, remainingWeekNumber, reportWeekNumber, summaryFilterValue, entityStartCountModelTable])

    useEffect(() => {
        if (selectedYear == currentYear) {
            handleQuaterClick(parseInt(currentWeekNumber / 13) + ((currentWeekNumber % 13) ? 1 : 0));
        } else {
            handleQuaterClick(1);
        }
    }, [selectedYear, currentYear, currentWeekNumber]);

    const handleQuaterClick = (qn) => {
        var startWeekNumber = 1;
        var endWeekNumber = 52;
        var filterStartDate = "";
        var filterEndDate = "";
        var startYearDate = "";
        var endYearDate = "";
        var createdat = entityInfo.createdat;
        var weeks = 0;
        var repWeekNumber = 1;
        var remWeekNumber = 1;
        var repCurrrentWeekNumber = 1;

        setCurrentQuarterNumber(qn);
        if (qn > 0) {
            weeks = (selectedYear - entityYear) * 52 + (qn - 1) * 13;
            startWeekNumber = weeks;
            endWeekNumber = weeks + 12;

            if (currentWeekNumber > qn * 13) {
                repWeekNumber = 13;
                remWeekNumber = 0;
                repCurrrentWeekNumber = qn * 13;

            } else {
                if (selectedYear == currentYear) {
                    repWeekNumber = currentWeekNumber % 13;
                    remWeekNumber = 13 - (currentWeekNumber % 13);
                    repCurrrentWeekNumber = currentWeekNumber;
                } else {
                    repWeekNumber = 13;
                    remWeekNumber = 0;
                    repCurrrentWeekNumber = qn * 13;
                }
            }
        } else if (qn == 0) {
            weeks = (selectedYear - entityYear) * 52;
            startWeekNumber = weeks;
            endWeekNumber = weeks + 51;
            if (selectedYear == currentYear) {
                repWeekNumber = currentWeekNumber;
                remWeekNumber = 52 - currentWeekNumber;
                repCurrrentWeekNumber = currentWeekNumber;
            } else {
                repWeekNumber = 52;
                remWeekNumber = 0;
                repCurrrentWeekNumber = 52;
            }
        } else {
            startWeekNumber = currentWeekNumber;
            endWeekNumber = currentWeekNumber;
        }

        setReportWeekNumber(repWeekNumber);
        setRemainingWeekNumber(remWeekNumber);
        setReportCurrentWeekNumber(repCurrrentWeekNumber);

        weeks = (selectedYear - entityYear) * 52;
        startYearDate = getStartDateFromEntityDateAfterWeeks(createdat, weeks)
        endYearDate = getEndDateFromEntityDateAfterWeeks(createdat, weeks + 51);
        filterStartDate = getYearAndWeekNumberAfterWeeks(createdat, startWeekNumber);
        filterEndDate = getYearAndWeekNumberAfterWeeks(createdat, endWeekNumber);

        setIsLoading(true);
        axios
            .post('/recordofweekly', { entity, startDate: startYearDate, endDate: endYearDate })
            .then((response) => {
                setIsLoading(false);
                var records = response?.data?.rows;

                if (!isEmpty(records)) {
                    let summary = {};
                    var hasToric = false;
                    var hasMonofocal = false;
                    var hasMultifocal = false;

                    records.map((record) => {
                        if (!isEmpty(record.model)) {
                            var model = record.model;
                            var modelv = model;
                            var tpower = record.tpower;

                            if (isEmpty(tpower)) {
                                tpower = 0;
                            }
                            tpower = parseFloat(tpower);

                            // if (tpower != 0) {
                            //     modelv = model + `T${tpower}`;
                            // }

                            if (isEmpty(summary[modelv]))
                                summary[modelv] = {
                                    model: modelv,
                                    checkin: 0,
                                    checkout: 0,
                                    YTD: 0,
                                };

                            summary[modelv].YTD += record.checkout;

                            if ((record.year > filterStartDate.year && record.year < filterEndDate.year)
                                || (record.year == filterStartDate.year && filterStartDate.weekNumber >= record.weeknumber)
                                || (record.year == filterEndDate.year && filterEndDate.weekNumber <= record.weeknumber)) {
                                summary[modelv].checkin += record.checkin;
                                summary[modelv].checkout += record.checkout;
                            }
                            summary[modelv].tpower = tpower;
                            if (tpower != 0) {
                                summary[modelv].turnrate = 1.7;
                                hasToric = true;
                            } else if (["XY1-G", "XY1-GP"].includes(modelv)) {
                                summary[modelv].turnrate = 2.5;
                                hasMultifocal = true;
                            } else {
                                summary[modelv].turnrate = 4.0;
                                hasMonofocal = true;
                            }
                        }
                        var temp = summaryFilterOptions;

                        summaryFilterOptions[1].disabled = !hasMonofocal;
                        summaryFilterOptions[2].disabled = !hasToric;
                        summaryFilterOptions[3].disabled = !hasMultifocal;

                        setValidSummaryFilterOptions(temp);
                        return record;
                    });

                    setSummaryAll(summary);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    }

    const getGridItemButton = (state, quarterNumber) => {
        return (<Grid item xs={2} className={self_classes.alignCenter}><Button
            variant="contained"
            color={quarterState[state].theme}
            style={{ backgroundColor: quarterState[state].color }}
            onClick={() => handleQuaterClick(quarterNumber)}
            disabled={quarterState[state].disabled}
        >
            {t(localeProgress[state])}
        </Button></Grid>)
    }

    const getSummaryInfoDiv = (content) => {
        return (<div className='summary-info'>{content}</div>)
    }

    return (
        <>
            {isLoading && <CustomLoadingView />}
            <Grid item xs={12}>
                <Widget>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {t("tr_week")} {getSummaryInfoDiv(reportWeekNumber)} {t("tr_remaining_weeks")} {getSummaryInfoDiv(remainingWeekNumber)}
                        </Grid>
                        <Grid item md={3}>
                            {getSummaryInfoDiv(entityName)}
                        </Grid>
                        <Grid item md={3}>
                            {t("tr_week")} {getSummaryInfoDiv(reportCurrentWeekNumber)} {t("tr_status")}
                        </Grid>
                        <Grid item md={3}>
                            {t("tr_stock_opened_on")} {getSummaryInfoDiv(entityInfo.createdat)}
                        </Grid>
                        <Grid item md={3}>
                            {t("tr_starting_stock")} {getSummaryInfoDiv(entityStartCount)}
                        </Grid>
                        <Grid item md={4}>
                            {getSummaryInfoDiv(entityInfo?.manager?.email)}
                        </Grid>
                        <Grid item md={4}>
                            {t("tr_evalution")} : {getSummaryInfoDiv(evalutionState)}
                        </Grid>
                        {(!isEmpty(summaryColumn) && !isEmpty(summaryRows)) &&
                            <>
                                <Grid item xs={12}>
                                    <div className="summary-table-content">
                                        <table className="summary-table">
                                            <colgroup>
                                                {summaryColumn.map(() => (
                                                    <col width="100" />
                                                ))}
                                            </colgroup>
                                            <thead className="summary-table-thead">
                                                <tr>
                                                    {summaryColumn.map((c) => {
                                                        var render = c;
                                                        if (c == totalTitle) {
                                                            render = t("tr_total");
                                                        } else if (c == "Total by Toric") {
                                                            render = t("tr_total_by_toric");
                                                        } else if (c == "Total by Multifocal") {
                                                            render = t("tr_total_by_multifocal");
                                                        } else if (c == "Total by Monofocal") {
                                                            render = t("tr_total_by_monofocal");
                                                        }
                                                        return (<th className="summary-table-th">{render}</th>);
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(summaryRows).map((key) => (
                                                    <tr key={key} className="summary-table-tr">
                                                        {/* <td className="summary-table-td-leader">{key}</td> */}
                                                        {summaryRows[key].map((value, index) => {
                                                            return (
                                                                <td
                                                                    className={index == 0 ? "summary-table-td-leader" : "summary-table-td"}
                                                                >
                                                                    {index == 0 && !isEmpty(localeTable[key]) ? t(localeTable[key]) : value}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display={"flex"} justifyContent={"center"}>
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: t("tr_all"),
                                                    value: modelGroups.All.title,
                                                },
                                                {
                                                    label: t("tr_monofocal"),
                                                    value: modelGroups.Monofocal.title,
                                                    disabled: validSummaryFilterOptions[1].disabled
                                                },
                                                {
                                                    label: t("tr_toric"),
                                                    value: modelGroups.Toric.title,
                                                    disabled: validSummaryFilterOptions[2].disabled
                                                },
                                                {
                                                    label: t("tr_multifocal"),
                                                    value: modelGroups.Multifocal.title,
                                                    disabled: validSummaryFilterOptions[3].disabled
                                                },
                                            ]}
                                            onChange={({ target: { value } }) => { setTableFilterValue(value) }}
                                            value={summaryFilterValue}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Box>

                                </Grid>
                            </>
                        }
                    </Grid>
                </Widget>
            </Grid>
            {!isEmpty(entityInfo.manager) && <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button variant="contained" color="secondary" onClick={() => setIsActionPlanView(!isActionPlanView)}>{isActionPlanView ? t("tr_go_to_timeline") : t("tr_go_to_actions")}</Button>
                </Box>
            </Grid>}
            <Grid item xs={12}>
                <Widget>
                    {isActionPlanView && !isEmpty(entityInfo.manager) ?
                        <FullFeaturedCrudGrid entityName={entityName} assigner={entityInfo.users} user={entityInfo.manager?.email} evalutionState={evalutionState} evalutionCycle={currentQuarterNumber} />
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {t("tr_full_quarter_reports")}
                            </Grid>
                            <>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_year_to_date_report")}
                                </Grid>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_week")} 13
                                </Grid>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_week")} 26
                                </Grid>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_week")} 39
                                </Grid>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_week")} 52
                                </Grid>
                                <Grid item md={2} className={self_classes.alignCenter}>
                                    {t("tr_full_year")}
                                </Grid>
                            </>
                            <>
                                <Grid item xs={2} className={self_classes.alignCenter}>
                                    <Select
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                    >
                                        {years.map((year) => {
                                            return (<MenuItem value={year}>{year}</MenuItem>)
                                        })}
                                    </Select>
                                </Grid>
                                {quarterRange.map((r) => {
                                    if (currentYear == selectedYear) {
                                        if (currentWeekNumber >= r.start && currentWeekNumber <= r.end) {
                                            return getGridItemButton("IN_PROGRESS", r.number);
                                        } else if (currentWeekNumber > r.start) {
                                            return getGridItemButton("VIEW", r.number);
                                        } else if (currentWeekNumber < r.end) {
                                            return getGridItemButton("NOT_STARTED", r.number);
                                        }
                                        return (<></>)
                                    } else {
                                        return getGridItemButton("VIEW", r.number);
                                    }
                                })}
                                {currentYear == selectedYear ?
                                    isEmpty(entityInfo.createdat) ? getGridItemButton("NOT_STARTED", 0) : getGridItemButton("IN_PROGRESS", 0) : getGridItemButton("VIEW", 0)}
                            </>
                        </Grid>}
                </Widget>
            </Grid>
        </>
    )
}
