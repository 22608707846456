import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter, useLocation } from "react-router-dom";
import axios from "../../services/http-common";

// locale
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";

// context
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";

function Reset(query) {
  var classes = useStyles();
  // locale
  const { t } = useTranslation();
  const history = useHistory();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [errorMsg, setErrorMSG] = useState(
    "Something is wrong with your login or password :("
  );
  var [mistmatch, setMismatch] = useState(false);
  var [newPwd, setNewPwd] = useState("");
  var [confirmPwd, setConfirmPwd] = useState("");
  var [token, setToken] = useState("");

  const search = useLocation().search;

  useEffect(() => {
    if (newPwd === confirmPwd) setMismatch(false);
    else setMismatch(true);
  }, [newPwd, confirmPwd]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    var resetToken = searchParams.get("token");
    if (isEmpty(resetToken)) {
      history.push("/forgot");
    }
    var params = { resetToken };
    axios
      .post("/user/validateToken", params)
      .then((response) => {
        setToken(resetToken);
      })
      .catch((error) => {
        history.push("/forgot");
      });
  }, [search, history]);

  const handleResetPassword = () => {
    setError(false);
    setIsLoading(true);

    if (!!newPwd) {
      const params = {
        newpassword: newPwd,
        resetToken: token,
      };

      axios
        .post("/user/reset", params)
        .then((response) => {
          setError(false);
          setIsLoading(false);
          history.push("/login");
        })
        .catch((error) => {
          setErrorMSG(error.response.data.message);
          setError(true);
          setIsLoading(false);
        });
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>
          e<span style={{ fontFamily: "DinProBold" }}>Consignment</span>
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <img src={logo} alt="logo" className={classes.logtypeImage1} />
        <LanguageSwitcher />
        <div className={classes.form}>
          <React.Fragment>
            <Fade
              in={error}
              style={!error ? { display: "none" } : { display: "block" }}
            >
              <Typography color="secondary" className={classes.errorMessage}>
                {errorMsg}
              </Typography>
            </Fade>
            <h1>Reset your password</h1>
            <TextField
              id="new_password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={newPwd}
              onChange={(e) => setNewPwd(e.target.value)}
              margin="normal"
              placeholder={t("tr_new_password")}
              type="password"
              fullWidth
            />
            <TextField
              id="confirm_password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={confirmPwd}
              onChange={(e) => setConfirmPwd(e.target.value)}
              margin="normal"
              placeholder={t("tr_confirm_your_password")}
              type="password"
              fullWidth
            />
            <Fade
              in={mistmatch}
              style={!mistmatch ? { display: "none" } : { display: "block" }}
            >
              <Typography color="secondary" className={classes.errorMessage}>
                {t("tr_the_password_does_not_match")}
              </Typography>
            </Fade>
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={newPwd.length === 0 || mistmatch == true}
                  onClick={() => {
                    handleResetPassword();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t("tr_reset_password")}
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Reset);
