import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import axios from "../../services/http-common";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import { useSelector } from "react-redux";
import CheckOutCalender from "../../components/CheckOutCalendar/CheckOutCalender";
import LoadingView from "../../components/LoadingView/LoadingView";
import { isEmpty } from "lodash";
import {
  ROLE_ADMIN,
  ROLE_MAIN,
  ROLE_MID,
  ROLE_NUR,
} from "../../constant/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { convertToFixed } from "../../services/convert";
import BellCurveLineChart from "../../components/Charts/BellCurveLineChart";
import ExpiryBarChart from "../../components/Charts/ExpiryBarChart";
import ToricDistribution from "../../components/Charts/ToricDistibutionChart";
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t } = useTranslation();
  var classes = useStyles();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const regionName = useSelector((state) => state.user.regionName);
  const region = useSelector((state) => state.user.region);
  const role = useSelector((state) => state.user.role);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);

  const history = useHistory();

  const [checkins, setCheckins] = useState({});
  const [avgTurnover, setAvgTurnover] = useState({});
  const [minmaxTurnover, setMinmaxTurnover] = useState({
    minTurnover: 0,
    maxTurnover: 0,
  });

  useEffect(() => {
    if (role !== ROLE_ADMIN && role !== ROLE_MAIN)
      if (role === ROLE_MID) history.push("/app/country");
      else if (role === ROLE_NUR) history.push("/app/entity");
  }, [role, history]);

  useEffect(() => {
    var filter = { startDate, endDate, model, brand };
    if (region == undefined)
      return;

    axios
      .post(`/view/${region}/getRegionChekinStateByMonth`, filter)
      .then((response) => {
        if (!isEmpty(response.data)) setCheckins(response.data[0]);
        else setCheckins({});
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post(`/view/${region}/getRegionAvgTurnOverState`, filter)
      .then((response) => {
        setAvgTurnover(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post(`/view/${region}/getRegionMinAndMaxTurnOverState`, filter)
      .then((response) => {
        setMinmaxTurnover(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, model, brand, startDate, endDate]);

  return (
    <>
      <LoadingView />
      <PageTitle region={regionName} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget>
            <CheckOutCalender />
          </Widget>
        </Grid>
        <Grid item xs={6}>
          <Widget>
            <BellCurveLineChart />
          </Widget>
        </Grid>
        <Grid item xs={6}>
          <Widget>
            <ExpiryBarChart />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <ToricDistribution />
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title={t("tr_expiry_date")}
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  {t("tr_less")} 6m
                </Typography>
                <Typography size="md">
                  {!isEmpty(checkins) ? checkins.m6 : 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  {t("tr_less")} 12m
                </Typography>
                <Typography size="md">
                  {!isEmpty(checkins) ? checkins.m6_12 : 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  More 12m
                </Typography>
                <Typography size="md">
                  {!isEmpty(checkins) ? checkins.m12 : 0}
                </Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title={t("tr_average_turnover")}
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  30 {t("tr_days")}
                </Typography>
                <Typography size="md">
                  {!isEmpty(avgTurnover) ? convertToFixed(avgTurnover.d30) : 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  90 {t("tr_days")}
                </Typography>
                <Typography size="md">
                  {!isEmpty(avgTurnover) ? convertToFixed(avgTurnover.d90) : 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  180 {t("tr_days")}
                </Typography>
                <Typography size="md">
                  {!isEmpty(avgTurnover) ? convertToFixed(avgTurnover.d180) : 0}
                </Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title={t("tr_min_max_turnover")}
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Min
                </Typography>
                <Typography size="md">
                  {!isEmpty(minmaxTurnover)
                    ? convertToFixed(minmaxTurnover.minTurnover)
                    : 0}
                  %
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Max
                </Typography>
                <Typography size="md">
                  {!isEmpty(minmaxTurnover)
                    ? convertToFixed(minmaxTurnover.maxTurnover)
                    : 0}
                  %
                </Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
