import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// locale
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// logo

// context
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../services/http-common";

function ContactUS(props) {
  var classes = useStyles();
  // locale
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [email, setEmail] = useState("");
  var [fullName, setFullName] = useState("");
  var [message, setMessage] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);
    setError(false);
    axios.post(`/contactus`, { fullName, email, message })
      .then(resp => {
        setIsLoading(false);
        setEmail("");
        setFullName("");
        setMessage("");
      })
      .catch(e => {
        console.log(e);
        setIsLoading(false);
        setError(true);
      });
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.supportFormContainer}>
        {/* <img src={logo} alt="logo" className={classes.logtypeImage1} />
        <LanguageSwitcher /> */}
        <div >
          <Typography>
            <h1>Safety and User Privacy Information</h1>

            Welcome to eConsignment! Your safety and privacy are our top priorities. Here's some important information about how we handle your data and ensure a safe experience:
            <h4>User Data Privacy:</h4>
            We respect your privacy and do not collect or store any personal information through the use of your device's camera for barcode scanning. Your data remains on your device and is not shared with third parties.
            <h4>Camera Access:</h4>
            eConsignment requires access to your device's camera to scan barcodes. We use this access solely for the purpose of barcode scanning, and we do not record or store images or videos captured by your camera.
            <h4>Security Measures:</h4>
            We employ advanced security measures to protect your data and ensure a safe environment within our app. We are committed to safeguarding your information from unauthorized access.
            <h4>Age-Appropriate Usage:</h4>
            Please note that eConsignment is intended for users of all ages. However, it's essential for parents and guardians to supervise and guide children's use of this app, especially when using the camera feature.
            <h4>User Reporting:</h4>
            If you encounter any issues, inappropriate content, or have safety concerns while using our app, please don't hesitate to contact our support team at [Your Support Email or Contact Information]. We take user feedback seriously and will address any concerns promptly.
            <h4>Updates and Safety Improvements:</h4>
            We regularly update our app to enhance user safety and security. Please ensure you're using the latest version of eConsignment to take advantage of the most recent safety features and improvements.

            <p>Thank you for choosing eConsignment. We're committed to providing a safe and enjoyable experience for all our users. If you have any questions or need further assistance, please reach out to our support team via the form below or via email <a href="mailto:notifications@consi.app">notifications@consi.app</a>.</p>
          </Typography>
          <div className={classes.formContainer}>
            {/* <img src={logo} alt="logo" className={classes.logtypeImage1} />
        <LanguageSwitcher /> */}
            <div className={classes.form}></div>
            <TextField
              id="fullName"
              label={t("tr_contact_us_full_name")}
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              margin="normal"
              placeholder={t("tr_contact_us_full_name")}
              fullWidth
            />
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder={t("tr_email_address")}
              label={t("tr_email_address")}
              type="email"
              fullWidth
            />

            <TextField
              id="message"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              placeholder={t("tr_contact_us_message")}
              label={t("tr_contact_us_message")}
              multiline
              rows={5}
              fullWidth
            />

            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    email.length === 0 || fullName.length === 0 || message.length === 0
                  }
                  onClick={() => { handleSubmit(); }
                    // loginUser(
                    //   dispatch,
                    //   loginValue,
                    //   passwordValue,
                    //   history,
                    //   setIsLoading,
                    //   setError
                    // )
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t("tr_contact_us_submit")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid >
  );
}

export default withRouter(ContactUS);
