import moment from "moment";

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
const dateSort = (dateA, dateB) => moment(dateA).diff(moment(dateB));

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const defaultSort = (key) => {
    return (a, b) => {
        if (a[key] < b[key]) return -1;
        if (b[key] < a[key]) return 1;
        return 0;
    }
};

const defaultStringSort = (key) => {
    return (a, b) => {
        return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    }
};

export const Sorter = {
    DEFAULT: defaultSort,
    STRING: defaultStringSort,
    DATE: dateSort
};
