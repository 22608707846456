import React, { useEffect, useState } from 'react';
import { Form, Popconfirm, Table, Typography, Select } from 'antd';
import { ACTIONSTATUS } from '../../constant/constants';
import axios from "../../services/http-common";
import { Sorter } from '../../services/sorter';
import { useTranslation } from "react-i18next";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'select' ? <Select
    defaultValue={record[dataIndex]}
    options={ACTIONSTATUS.map((actionstatus) => ({
      label: actionstatus,
      value: actionstatus,
    }))}
  /> : <></>;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTable = ({ dataSource, isShowOnlyNotComplete }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState(dataSource);
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };

  useEffect(() => {
    if (isShowOnlyNotComplete == true) {
      setData(originData.filter(d => d.status != ACTIONSTATUS[3]));
    } else {
      setData(originData);
    }
  }, [isShowOnlyNotComplete, originData])


  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        axios.put(`/actionplan/${item.id}`, newData[index])
          .then(() => {
            setOriginData(originData.map(source => {
              if (source.id == item.id) {
                source.status = newData[index].status;
              }
              return source;
            }));
            // setData(newData);
            setEditingKey('');
          })
          .catch(e => console.log(e));
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={[
          {
            title: t("tr_action"),
            dataIndex: 'action',
            width: '20%',
            editable: false,
            sorter: {
              compare: Sorter.DEFAULT('action')
            }
          },
          {
            title: t("tr_expected"),
            dataIndex: 'expected',
            width: '25%',
            editable: false,
            sorter: {
              compare: Sorter.DEFAULT('expected')
            }
          },
          {
            title: t("tr_deadline"),
            dataIndex: 'deadline',
            width: '15%',
            editable: false,
            sorter: {
              compare: Sorter.DATE
            }
          },
          {
            title: t("tr_status"),
            dataIndex: 'status',
            width: '20%',
            editable: true,
            sorter: {
              compare: Sorter.DEFAULT('status')
            }
          },
          {
            title: t("tr_operation"),
            dataIndex: 'operation',
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link
                    onClick={() => save(record.key)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    {t("tr_save")}
                  </Typography.Link>
                  <Popconfirm title={t("tr_sure_to_cancel")} onConfirm={cancel}>
                    <a>{t("tr_cancel")}</a>
                  </Popconfirm>
                </span>
              ) : (
                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  {t("tr_edit")}
                </Typography.Link>
              );
            },
          },
        ].map((col) => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record) => ({
              record,
              inputType: col.dataIndex === 'status' ? 'select' : 'none',
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
            }),
          };
        })}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};
export default EditableTable;