import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../services/http-common";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export default function ExpiryBarChart() {
  const { t } = useTranslation();
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const region = useSelector((state) => state.user.region);
  const country = useSelector((state) => state.user.country);
  const entity = useSelector((state) => state.user.entity);
  const model = useSelector((state) => state.user.model);
  const brand = useSelector((state) => state.user.brand);

  const [bars, setBars] = useState([]);

  useEffect(() => {
    if (!isEmpty(bars))
      setBars(
        [
          {
            expire: t("tr_expired"),
            checkin: bars[0].checkin,
          },
          {
            expire: `3 ${t("tr_months")}`,
            checkin: bars[1].checkin,
          },
          {
            expire: `6 ${t("tr_months")}`,
            checkin: bars[2].checkin,
          },
          {
            expire: `12 ${t("tr_months")}`,
            checkin: bars[3].checkin,
          },
          {
            expire: `12 ${t("tr_months")}+`,
            checkin: bars[4].checkin,
          },
        ]
      )
  }, [t])

  useEffect(() => {
    var filter = { country, startDate, endDate, model, brand, entity };

    if (region == undefined) {
      return;
    }
    axios
      .post(`/view/${region}/getAdvancedExpiryState`, filter)
      .then((response) => {
        let resp = response.data[0];

        if (isEmpty(resp)) {
          setBars([
            {
              expire: t("tr_expired"),
              checkin: 0,
            },
            {
              expire: `3 ${t("tr_months")}`,
              checkin: 0,
            },
            {
              expire: `6 ${t("tr_months")}`,
              checkin: 0,
            },
            {
              expire: `12 ${t("tr_months")}`,
              checkin: 0,
            },
            {
              expire: `12 ${t("tr_months")}+`,
              checkin: 0,
            },
          ]);
        } else {
          setBars([
            {
              expire: t("tr_expired"),
              checkin: resp.m0,
            },
            {
              expire: `3 ${t("tr_months")}`,
              checkin: resp.m3,
            },
            {
              expire: `6 ${t("tr_months")}`,
              checkin: resp.m3_6,
            },
            {
              expire: `12 ${t("tr_months")}`,
              checkin: resp.m6_12,
            },
            {
              expire: `12 ${t("tr_months")}+`,
              checkin: resp.m12,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [region, country, startDate, endDate, model, brand, entity]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={bars}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="expire" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar name={t("tr_checkin")} dataKey="checkin" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}
