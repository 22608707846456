const mock = {
  regions: [
    {
      value: "1",
      label: "EMEA NW",
    },
    {
      value: "2",
      label: "EMEA SE",
    },
    {
      value: "3",
      label: "APAC",
    },
    {
      value: "4",
      label: "China",
    },
    {
      value: "5",
      label: "Japan",
    },
    {
      value: "6",
      label: "Other3",
    },
  ],
  countries: [
    {
      value: "0",
      label: "All Countries",
    },
    {
      value: "1",
      label: "C1",
    },
    {
      value: "2",
      label: "C2",
    },
    {
      value: "3",
      label: "C3",
    },
  ],
  tags: [
    "Search1",
    "Search2",
    "Search3",
    "Search4",
    "Search5",
    "Search6",
    "Search7",
  ],
};

export default mock;
