import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "../../services/http-common";

// locale
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";

// context
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import { validateEmail } from "../../services/validate";

function Forgot(props) {
  var classes = useStyles();
  // locale
  const { t } = useTranslation();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [isEmailSent, setIsEmailSent] = useState(false);
  var [errorMsg, setErrorMSG] = useState(
    "Something is wrong with your login or password :("
  );
  var [resetEmail, setResetEmail] = useState("");

  const handleForgotPassword = () => {
    setError(false);
    setIsLoading(true);

    if (!!resetEmail) {
      const params = {
        email: resetEmail,
      };

      axios
        .post("/user/forgot", params)
        .then((response) => {
          setError(false);
          setIsLoading(false);
          setIsEmailSent(true);
        })
        .catch((error) => {
          setErrorMSG(error.response.data.message);
          setError(true);
          setIsLoading(false);
        });
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>
          e<span style={{ fontFamily: "DinProBold" }}>Consignment</span>
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <img src={logo} alt="logo" className={classes.logtypeImage1} />
        <LanguageSwitcher />
        <div className={classes.form}>
          <React.Fragment>
            {!isEmailSent && (
              <>
                <Fade
                  in={error}
                  style={!error ? { display: "none" } : { display: "block" }}
                >
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {errorMsg}
                  </Typography>
                </Fade>
                <h1>{t("tr_reset_your_password")}</h1>
                <Typography>
                  {t("tr_enter_the_email_address_associated_with_your_account_and_we")}
                </Typography>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  margin="normal"
                  placeholder={t("tr_email_address")}
                  type="email"
                  fullWidth
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      disabled={
                        resetEmail.length === 0 || !validateEmail(resetEmail)
                      }
                      onClick={() => {
                        handleForgotPassword();
                      }}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("tr_send_reset_code")}
                    </Button>
                  )}
                </div>
              </>
            )}

            {isEmailSent && (
              <>
                <h3>
                  Thanks! If {resetEmail} matches an email we have on file, then
                  we've sent you an email containing further instructions for
                  resetting your password.
                </h3>
                <Typography>
                  If you haven't received an email in 5 minutes, check your
                  spam, or
                  <Button
                    color="primary"
                    onClick={() => {
                      handleForgotPassword();
                    }}
                  >
                    resend
                  </Button>
                  or
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsEmailSent(false);
                    }}
                  >
                    try a different email
                  </Button>
                </Typography>
              </>
            )}
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Forgot);
