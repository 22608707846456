import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { ROLE_MID } from "../../constant/constants";
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Popconfirm } from 'antd';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { isEmpty } from "lodash";
import AccountSettingDlg from "../../components/Dialog/AccountSettingDlg";

import CustomLoadingView from "../../components/LoadingView/CustomLoadingView";
import { Segmented } from "antd";

import axios from "../../services/http-common";
import { calculateDiffDays } from "../../services/convert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const CHECKIN_STATE = "Stock State";
const ALL_HISTORY = "All histories";

export default function EntityNormal() {
    const { t } = useTranslation();
    const [dlgopen, setDlgOpen] = useState(false);
    const [alertmsg, setAlertMsg] = useState("");
    const [alerseverity, setAlertSeverity] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const [alertopen, setAlertOpen] = useState(false);

    const role = useSelector((state) => state.user.role);
    const entity = useSelector((state) => state.user.entity);
    const region = useSelector((state) => state.user.region);
    const country = useSelector((state) => state.user.country);

    const [entityInfo, setEntityInfo] = useState({});
    const [records, setRecords] = useState([]);
    const [iolData, setIOLData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [state, setState] = useState(CHECKIN_STATE);

    const handleDlgClose = () => {
        setDlgOpen(false);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    const getAllHistories = (isNeedLoadingView = true) => {
        if (isNeedLoadingView)
            setIsLoading(true);
        axios
            .post(`/view/${region}/${country}/entity/${entity}`)
            .then((response) => {
                setIsLoading(false);
                setRecords(response.data?.rows);
                setHistoryData(
                    response.data.rows.map((scan) => {
                        return [
                            scan.serial,
                            scan.model,
                            scan.diopter,
                            scan.expiredate,
                            scan.checkin ? t("tr_checkin") : t("tr_checkout"),
                            scan.scannedat,
                        ];
                    })
                );
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    const getIOLs = (isNeedLoadingView = true) => {
        if (isNeedLoadingView)
            setIsLoading(true);
        axios
            .get(
                `/iol?filter={"curentity":${entity},"checkin":true}&sort=["id","ASC"]`
            )
            .then((response) => {
                setIsLoading(false);
                setIOLData(
                    response.data.map((iol) => {
                        let diff = calculateDiffDays(new Date(iol.updatedAt), new Date());
                        return [
                            iol.sn,
                            iol.model,
                            iol.diopter,
                            iol.expire,
                            diff < 1 ? `${diff} day` : `${diff} ${t("tr_days")}`,
                        ];
                    })
                );
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            getAllHistories(false);
            getIOLs(false);
        }, 5000); // Send request every 5 seconds

        axios
            .get(`/entityinfo/${entity}`)
            .then((res) => {
                setEntityInfo(res.data);
            })

        return () => {
            clearInterval(intervalId); // Cleanup the interval when the component unmounts
        };
    }, []);

    useEffect(() => {
        if (state === ALL_HISTORY) {
            getAllHistories();
        }
        else {
            getIOLs();
        }
    }, [region, country, entity, state]);

    const handleChange = (value) => {
        setState(value);
    };

    const handleIOLstate = (dataIndex) => {
        setIsLoading(true);
        axios
            .post(
                `/changeiolstate`,
                { ...records[dataIndex], entity }
            )
            .then((response) => {
                setIsLoading(false);
                var res = response.data;

                if (!isEmpty(res)) {
                    if (res?.status == 200) {
                        getAllHistories();
                        setAlertSeverity("success");
                    } else {
                        setAlertSeverity("error");
                    }
                    setAlertMsg(res?.message);
                    setAlertOpen(true);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setAlertSeverity("error");
                setAlertMsg(error.message);
                setAlertOpen(true);
            });
    }

    return (
        <>
            {isLoading && <CustomLoadingView />}
            <AccountSettingDlg open={dlgopen} onClose={handleDlgClose} />
            <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert onClose={handleAlertClose} severity={alerseverity} sx={{ width: '100%' }}>
                    {alertmsg}
                </MuiAlert>
            </Snackbar>
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box mx={1}>
                        <Segmented
                            options={[
                                {
                                    value: CHECKIN_STATE,
                                    label: t("tr_stock_state")
                                },
                                {
                                    value: ALL_HISTORY,
                                    label: t("tr_all_histories")
                                }]}
                            onChange={handleChange}
                            value={state}
                        />
                    </Box>
                    <Box display={"flex"}>
                        <Box sx={{ mx: 1 }}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    setDlgOpen(true);
                                }}
                            >
                                {t("tr_settings")}
                            </Button>
                        </Box>
                        <Box sx={{ mx: 1 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    history.push("/return");
                                }}
                            >
                                {t("tr_return")}
                            </Button>
                        </Box>
                        {role <= ROLE_MID && (
                            <Box sx={{ mx: 1 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        history.push("/stockcheck");
                                    }}
                                >
                                    {t("tr_stock_check")}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {state === ALL_HISTORY ?
                    <>
                        <MUIDataTable
                            title={"Scan History"}
                            data={historyData}
                            columns={
                                [
                                    t("tr_serial_number"),
                                    t("tr_model"),
                                    t("tr_diopter"),
                                    t("tr_expiry_date"),
                                    t("tr_check"),
                                    t("tr_updated_date"),
                                    {
                                        name: t("tr_actions"),
                                        options: {
                                            filter: false,
                                            sort: false,
                                            viewColumns: false,
                                            empty: true,

                                            customBodyRenderLite: (dataIndex) => {
                                                if (records[dataIndex]?.checkin == false) {
                                                    return (
                                                        <Popconfirm
                                                            title="Change IOL state."
                                                            description="Are you sure to change a state of  this iol to checkin?"
                                                            okText="Yes"
                                                            cancelText="No"
                                                            onConfirm={() => handleIOLstate(dataIndex)}
                                                        >
                                                            <IconButton><CheckBoxIcon color="success" /></IconButton>
                                                        </Popconfirm>
                                                    );
                                                }
                                            }
                                        }
                                    },
                                ]
                            }
                            options={{
                                selectableRows: "none",
                                setRowProps: (row) => {
                                    if (row[4] === "Checkout") {
                                        return {
                                            style: { background: "#71C9CE" },
                                        };
                                    } else {
                                        return {
                                            style: { background: "#F6F4EB" },
                                        };
                                    }
                                },
                                textLabels: {
                                    body: {
                                        noMatch: t("tr_sorry_no_matching_records_found"),
                                    },
                                    pagination: {
                                        rowsPerPage: t("tr_rows_per_page"),
                                    },
                                }
                            }}
                        />
                    </> : <>
                        <MUIDataTable
                            title={"IOLs"}
                            data={iolData}
                            columns={
                                [
                                    t("tr_serial_number"),
                                    t("tr_model"),
                                    t("tr_diopter"),
                                    t("tr_expiry_date"),
                                    t("tr_timeonhand_kpi"),
                                ]
                            }
                            options={{
                                selectableRows: "none",
                                textLabels: {
                                    body: {
                                        noMatch: t("tr_sorry_no_matching_records_found"),
                                    },
                                    pagination: {
                                        rowsPerPage: t("tr_rows_per_page"),
                                    },
                                },
                                setRowProps: (row) => {
                                    var notification = 1;
                                    if (entityInfo.reportexpireinmonth != undefined) {
                                        notification = entityInfo.reportexpireinmonth;
                                    }
                                    var diff = calculateDiffDays(new Date(), new Date(row[3]));

                                    if (diff < 30) {
                                        return {
                                            style: { background: 'lightcoral' },
                                        };
                                    } else if (diff < 30 * notification) {
                                        return {
                                            style: { background: 'yellow' },
                                        };
                                    }
                                },
                            }}
                        /></>}
            </Grid>
        </>
    )
}
